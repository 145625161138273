import { types, flow, SnapshotIn, Instance } from "mobx-state-tree"
import { CreateModuleV2, DeleteModuleV2, GetModuleV2, GetModulesV2, UpdateModuleV2 } from "../../api/routes"
import { ModuleListFieldsSnapshotIn } from "./module-store"

/* ------------------------------------------
   Submodels for nested data structures
------------------------------------------ */
const PersonaModel = types.model("Persona", {
  summary: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
})

const RubricModel = types.model("Rubric", {
  id: types.string,
  fileName: types.string,
  content: types.string,
})

const ContextModel = types.model("Context", {
  id: types.string,
  fileName: types.string,
  summary: types.string,
})

/* ------------------------------------------
   Main module model
------------------------------------------ */
const ModuleFieldsModelV2 = types.model("ModuleFieldsV2", {
  moduleId: types.identifier, // Unique identifier
  companyId: types.string,
  createdById: types.string,
  createdByName: types.string,
  creationDate: types.number,
  moduleType: types.string, // e.g. "Activity" or "SkillChallenge"
  displayName: types.string,
  instructions: types.string,
  voice: types.string,
  callType: types.string,
  description: types.maybeNull(types.string),
  objective: types.maybeNull(types.string),
  persona: types.maybeNull(PersonaModel),
  rubric: types.maybeNull(RubricModel),
  context: types.maybeNull(ContextModel),
  timeLimit: types.maybeNull(types.string),
  links: types.maybeNull(types.array(types.string)),
  gradingAssistantId: types.maybeNull(types.string),
  roleplayAssistantId: types.maybeNull(types.string),
  isCreationDone: types.boolean,
  isScreenshareEnabled: types.boolean,
  isAutoGenerated: types.optional(types.boolean, false),
  recommendationReason: types.maybeNull(types.string),
  recommendedFor: types.maybeNull(types.string),

  // Optional fields for Activity modules (if needed)
  activityDetails: types.optional(types.maybeNull(types.string), null),

  // Optional fields for SkillChallenge modules (if needed)
  skillLevel: types.optional(types.maybeNull(types.string), null),
  challengeCriteria: types.optional(types.maybeNull(types.string), null),
})

const ModuleListFieldsModelV2 = types.model("ModuleListFieldsV2", {
  moduleList: types.array(ModuleFieldsModelV2),
})

export type ModuleListV2FieldsSnapshotIn = SnapshotIn<typeof ModuleListFieldsModelV2>
export type ModuleV2FieldsSnapshotIn = SnapshotIn<typeof ModuleFieldsModelV2>

export const ModuleListStoreModelV2 = types
  .model("ModuleListStoreV2", {
    userModules: types.maybeNull(ModuleListFieldsModelV2),
  })
  .actions(self => ({
    /* --------------------------------------
       1. Get a single module
    -------------------------------------- */
    getModule: flow(function* getModule(companyId: string, moduleId: string, userId: string) {
      try {
        const moduleInfoRes = yield GetModuleV2(companyId, moduleId, userId)
        if (moduleInfoRes.kind === "ok") {
          const newModule = ModuleFieldsModelV2.create(moduleInfoRes.data)
          if (!self.userModules) {
            self.userModules = ModuleListFieldsModelV2.create({
              moduleList: [newModule],
            })
          } else {
            self.userModules.moduleList.push(newModule)
          }
          return true
        }
      } catch (e) {
        console.error("[getModule] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       2. Get all modules for a company
    -------------------------------------- */
    getModules: flow(function* getModules(companyId: string, userId: string) {
      try {
        const managerModuleInfoRes = yield GetModulesV2(companyId, userId)
        if (managerModuleInfoRes.kind === "ok") {
          self.userModules = ModuleListFieldsModelV2.create({
            moduleList: managerModuleInfoRes.data.map((mod: any) => ModuleFieldsModelV2.create(mod)),
          })
          return true
        }
      } catch (e) {
        console.error("[getModules] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       3. Create (add) a new module
    -------------------------------------- */
    addModuleFromLegacyFlow: flow(function* addModule(moduleData) {
      try {
        const newModuleData = mapOldModuleToNew(moduleData)
        const response = yield CreateModuleV2(newModuleData)
        if (response.kind === "ok") {
          // Create new MST instance from server response
          const newModule = ModuleFieldsModelV2.create(response.data)

          if (!self.userModules) {
            self.userModules = ModuleListFieldsModelV2.create({
              moduleList: [newModule],
            })
          } else {
            self.userModules.moduleList.push(newModule)
          }
          return true
        }
      } catch (e) {
        console.error("[addModule] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       4. Delete a module
    -------------------------------------- */
    deleteModule: flow(function* deleteModule(companyId: string, moduleId: string, userId: string) {
      try {
        const response = yield DeleteModuleV2(companyId, moduleId, userId)
        if (response.kind === "ok") {
          // Remove the module from the store
          const index = self.userModules ?.moduleList.findIndex(mod => mod.moduleId === moduleId)
          if (index !== undefined && index > -1 && self.userModules) {
            self.userModules.moduleList.splice(index, 1)
          }
          return true
        }
      } catch (e) {
        console.error("[deleteModule] Error:", e)
      }
      return false
    }),

    /* --------------------------------------
       5. Update a module 
    -------------------------------------- */
    updateModule: flow(function* updateModule(
      companyId: string,
      moduleId: string,
      updatedFields: Partial<ModuleListV2FieldsSnapshotIn>,
    ) {
      try {
        const response = yield UpdateModuleV2(companyId, moduleId, updatedFields)
        if (response.kind === "ok") {
          const index = self.userModules ?.moduleList.findIndex(mod => mod.moduleId === moduleId)
          if (index !== undefined && index > -1 && self.userModules) {
            self.userModules.moduleList[index] = ModuleFieldsModelV2.create(response.data)
          }
          return true
        }
      } catch (e) {
        console.error("[updateModule] Error:", e)
      }
      return false
    }),
  }))
  .views(self => ({
    // Get a single module
    getModuleById(moduleId) {
      if (!self.userModules) return null
      return self.userModules.moduleList.find(m => m.moduleId === moduleId) || null
    },
    getAllModules() {
      if (!self.userModules) return []
      return self.userModules.moduleList
    },

    checkForInProgressModules() {
      if (!self.userModules) return false
      return self.userModules.moduleList.some(mod => !mod.isCreationDone)
    },

    doesModuleNameExist(name: string) {
      if (!self.userModules) return false

      return self.userModules.moduleList.find(mod => mod.displayName === name)
    },
  }))

/* ------------------------------------------
   Store instance type
------------------------------------------ */
export type ModuleListStoreV2 = Instance<typeof ModuleListStoreModelV2>

/**
 * Takes an old-style module object and returns an object
 * ready to be consumed by the new MST module store.
 */
export function mapOldModuleToNew(oldModule: any, moduleTypeOverride?: string) {
  // Convert presentation (boolean) to isScreenshareEnabled.
  // If the old data was "true"/"false" or just a boolean, simply coerce with !!
  const isScreenshareEnabled = !!oldModule.presentation

  // Convert doneCreation "Y"/"N" to a boolean
  const isCreationDone = oldModule.doneCreation === "Y"

  // Convert links from string to string[]
  // If it's an empty string, it becomes an empty array.
  const links = oldModule.links
    ? oldModule.links
      .split(",")
      .map((link: string) => link.trim())
      .filter(Boolean)
    : []

  // Convert created_date to a number if it's not already
  const creationDate =
    typeof oldModule.created_date === "number"
      ? oldModule.created_date
      : parseInt(oldModule.created_date, 10) || Date.now()

  return {
    // Identifiers & basics
    moduleId: oldModule.activity_id,
    companyId: oldModule.company,
    createdById: oldModule.manager_id,
    createdByName: oldModule.createdByName,
    creationDate: creationDate,
    callType: oldModule.testType,

    // Basic text fields
    displayName: oldModule.testName || "Untitled",
    instructions: "", // Not present in old schema on initial write
    voice: oldModule.voice || "",
    description: oldModule.testDecription || "",
    objective: oldModule.testObj || "",

    // Submodels
    persona: {
      //summary: oldModule.persona_summary || "",
      displayName: oldModule.persona_display_name || "",
    },
    rubric: {
      id: oldModule.rubric_id || "",
      fileName: oldModule.rubricFile || "",
      //content: oldModule.rubric_history || "",
    },
    context: {
      id: oldModule.context_id || "",
      fileName: oldModule.contextFile || "",
      //summary: oldModule.contextSummary || "",
    },

    // Arrays & optional fields
    timeLimit: oldModule.timeLimit || "",
    links,
    gradingAssistantId: oldModule.grading_assistant || "",
    roleplayAssistantId: oldModule.roleplay_assistant_id || "",

    // Booleans
    isCreationDone,
    isScreenshareEnabled,
    isAutoGenerated: false,

    //instructions: oldModule.roleplay_history || null,

    // If needed for "SkillChallenge" modules:
    skillLevel: null,
    challengeCriteria: null,

    // bring over the user_id for permission mapping
    moduleType: moduleTypeOverride ?? "Activity",

    user_id: oldModule.user_id,
  }
}
