import React from 'react';

const RolePlayCards = ({ salesActivities, setInspectRecCoaching, setInspectCoachingData }) => {


  if (!salesActivities || salesActivities.length === 0) {
    return (
      <div className="p-6 text-center text-gray-700">
        No agent generated recommended coaching for now.
      </div>
    );
  }

  return (
    <div className="mt-8 px-4">
      {/* Flexbox container that wraps when necessary */}
      <div className="flex flex-wrap -mx-4">
        {salesActivities.map((rec, index) => (
          <div
            key={index}
            className="w-full sm:w-1/2 lg:w-1/3 px-4 mb-8"
          >
            {/* Individual card */}
            <div className="flex flex-col justify-between p-4 h-60 bg-white border border-gray-300 rounded-md shadow-lg overflow-auto">
              <div>
                <h3 className="text-base font-semibold text-gray-800">
                  {rec.displayName}
                </h3>
                <p className="text-xs text-gray-600 mt-2">
                  Opportunity: {rec.targetedOppName}
                </p>
              </div>
              <button
                onClick={() => {
                  setInspectCoachingData(rec);
                  setInspectRecCoaching(true)
                }}
                className="mt-4 bg-pareBlue text-white font-semibold py-1 px-2 rounded hover:bg-indigo-500">
                View report
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RolePlayCards;
