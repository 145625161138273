import { SparklesIcon } from '@heroicons/react/16/solid'
import { XMarkIcon } from "@heroicons/react/24/outline"


const AgentCreationPopUp = ({ header, body, leftButtonLabel, rightButtonLabel, leftButtonFunction, rightButtonFunction, handleAgentParametersChange, teamTypes, loading }) => {


  return (<div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
          <form>
            <div className="space-y-12">

              <div className="border-b border-gray-900/10 pb-12">
                <div class="flex flex-row justify-between">
                  <div className="flex flex-row align-center">
                    <SparklesIcon aria-hidden="true" className="mr-4 size-6 text-pareBlue" />
                    <h2 className="text-base/7 font-semibold text-gray-900">Create an agent cluster</h2>
                  </div>
                  <XMarkIcon
                    aria-hidden="true"
                    onClick={leftButtonFunction}
                    className="size-6 text-gray cursor-pointer" />
                </div>
                <p className="mt-1 text-sm/6 text-gray-600">Agent clusters cover your teams and provide each team member with their own personalized agent coach</p>
                <div className="mt-10  gap-x-6 gap-y-8">
                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Team
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Select the team you want to create an agent cluster for
                    </label>
                    <div className="mt-2 grid grid-cols-1">
                      <select
                        onChange={(e) => handleAgentParametersChange("repScope", e.target.value)}
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      >
                        <option key="None" value="">Select value</option>
                        {teamTypes.map(team => (
                          <option key={team} value={team}>{team}</option>
                        ))}
                      </select>

                    </div>
                  </div>
                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Salesforce Object
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Select the salesforce object where the quota metric is located
                    </label>
                    <div className="mt-2 grid grid-cols-1">
                      <select onChange={(e) => handleAgentParametersChange("salesforceObject", e.target.value)}
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6">
                        <option value="">Select value</option>
                        <option value="Tasks">Tasks</option>
                        <option value="Opportunities">Opportunities</option>
                      </select>

                    </div>
                  </div>

                  <div className="pb-4">
                    <label htmlFor="email" className="block text-sm/6 font-semibold text-gray-900">
                      Quota salesforce field name
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the salesforce metric field name that tracks quota attainment
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => handleAgentParametersChange("quotaMetricFieldName", e.target.value)}
                        type="text"
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>
                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Quota Goal
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the quota attainment goal for this team
                    </label>
                    <div className="mt-2">
                      <input
                        type="number"
                        onChange={(e) => handleAgentParametersChange("quotaGoal", e.target.value)}
                        min={0}
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Quota threshold cut off
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the quota attainment percentage threshold to determine who the top perfomers will be (Ex: 90% of QA is high perfomer)
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => handleAgentParametersChange("quotaCutOff", e.target.value)}
                        type="number"
                        placeholder="Enter a whole number"
                        min={0}
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Quota time frame
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the time frame the quota attainment corresponds to
                    </label>
                    <div className="mt-2 grid grid-cols-1">
                      <select
                        onChange={(e) => handleAgentParametersChange("quotaTimeFrame", e.target.value)}
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      >
                        <option value="">Select value</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                      </select>

                    </div>
                  </div>


                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Competency generation frequency number
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the frequency at which competency profiles will be generated
                    </label>
                    <div className="mt-2">
                      <input
                        onChange={(e) => handleAgentParametersChange("compProfileFreqNum", e.target.value)}
                        type="number"
                        min={0}
                        max={30}
                        className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      />
                    </div>
                  </div>

                  <div className="pb-4">
                    <label className="block text-sm/6 font-semibold text-gray-900">
                      Competency generation frequency unit
                    </label>
                    <label className="mt-1 text-sm/6 text-gray-600">
                      Enter the unit of the frequency number previously selected
                    </label>
                    <div className="mt-2 grid grid-cols-1">
                      <select
                        onChange={(e) => handleAgentParametersChange("compProfileFreqUnit", e.target.value)}
                        className="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
                      >
                        <option value="">Select value</option>
                        <option value="d">Day(s)</option>
                        <option value="w">Week(s)</option>
                        <option value="m">Month(s)</option>
                      </select>

                    </div>
                  </div>

                </div>
              </div>

            </div>
          </form>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={!loading ? rightButtonFunction : null}>{loading ? 'Creating...' : rightButtonLabel}</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={leftButtonFunction}>{leftButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default AgentCreationPopUp