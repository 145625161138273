import { FlagIcon, RectangleStackIcon, DocumentTextIcon } from "@heroicons/react/20/solid"
import ProgressionModal from "../../components/LearningPath/progressionModal"
import { SparklesIcon } from "@heroicons/react/16/solid"

export const AssignmentTypeLabels = {
  Activity: "Activity",
  SkillChallenge: "Skill Challenge",
  LearningPath: "Learning Path",
}

// Updated column design
function getColumnDesign(title) {
  switch (title) {
    case "Assigned":
      return {
        dotColor: "bg-blue-600",
        pillBg: "bg-blue-100",
        pillText: "text-blue-800",
      }
    case "Pending Review":
      return {
        dotColor: "bg-yellow-600",
        pillBg: "bg-yellow-100",
        pillText: "text-yellow-800",
      }
    case "Completed":
      return {
        dotColor: "bg-green-600",
        pillBg: "bg-green-100",
        pillText: "text-green-800",
      }
    default:
      // Default styling if a column name doesn't match the above
      return {
        dotColor: "bg-gray-600",
        pillBg: "bg-gray-100",
        pillText: "text-gray-800",
      }
  }
}

// Card background colors based on assignment status
const getCardBgColor = status => {
  if (status === "Assigned") return "bg-blue-50"
  if (status === "Pending Review" || status === "In Progress") return "bg-yellow-50"
  if (status === "Completed") return "bg-green-50"
  if (status === "Not Passed") return "bg-blue-50"
  // Default for other statuses
  return "bg-white"
}

// Helper function to format dueDate from 'YYYY-MM-DD' to 'MM/DD/YYYY'
const formatDueDate = dateStr => {
  const dateObj = new Date(dateStr)
  // If the parse fails, just return the original string as a fallback
  if (Number.isNaN(dateObj.getTime())) {
    return dateStr
  }
  // Format as MM/DD/YYYY
  const month = String(dateObj.getMonth() + 1).padStart(2, "0")
  const day = String(dateObj.getDate()).padStart(2, "0")
  const year = dateObj.getFullYear()
  return `${month}/${day}/${year}`
}

export const Kanban = ({
  loading,
  columnConfig,
  groupedAssignments,
  handleAssignmentClick,
  overdueChecker,
  showProgressionModal,
  selectedAssignment,
  availableModules,
  setShowProgressionModal,
}) => {
  return (
    <div className="h-screen">
      <div className="max-w-7xl w-full">
        {loading ? (
          <div className="text-center text-gray-500">Loading assignments...</div>
        ) : (
          <div className="grid lg:grid-cols-3 gap-5">
            {columnConfig.map(({ title, key }) => {
              // Get the dot and pill colors for the column header
              const { dotColor, pillBg, pillText } = getColumnDesign(title)

              return (
                <div key={key} className="bg-white rounded px-4 py-2 shadow">
                  {/* Column Header */}
                  <div className="flex items-center justify-between mb-2">
                    {/* Dot + Pill on the left */}
                    <div className="inline-flex items-center space-x-2">
                      {/* Colored dot */}
                      <span className={`inline-block h-2 w-2 rounded-full ${dotColor}`} />
                      {/* Pill containing the column title */}
                      <span
                        className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-semibold ${pillBg} ${pillText}`}>
                        {title}
                      </span>
                    </div>

                    {/* Assignment count on the right */}
                    <p className="text-gray-400 text-sm">{groupedAssignments[key]?.length || 0}</p>
                  </div>

                  {/* Cards */}
                  <div className="space-y-2">
                    {groupedAssignments[key]?.map(assignment => (
                      <div
                        key={`${assignment.userId}-${assignment.displayName}`}
                        className={`p-3 mb-2 border rounded shadow-sm cursor-pointer ${getCardBgColor(
                          assignment.status,
                        )} hover:bg-gray-300`}
                        onClick={() => handleAssignmentClick(assignment)}>
                        {/* Icon + Title row */}
                        <div className="flex items-center space-x-2 mb-2">
                          {assignment.type === "Activity" ? (
                            <DocumentTextIcon aria-hidden="true" className="h-5 w-4 text-gray-700" />
                          ) : assignment.type === "LearningPath" ? (
                            <RectangleStackIcon aria-hidden="true" className="h-5 w-4 text-pareBlue" />
                          ) : null}

                          <h3 className="text-sm font-semibold text-gray-700">{assignment.displayName}</h3>
                        </div>

                        {/* Bold "Assigned to" and "Due Date" */}
                        <p className="text-xs text-gray-500 mb-2">
                          <span className="font-bold">Assigned to:</span> {assignment.userName}
                        </p>
                        <p className="text-xs text-gray-500 mb-2">
                          <span className="font-bold">Due Date:</span> {formatDueDate(assignment.dueDate)}
                        </p>

                        {/* Status Tag */}
                        <span
                          className={`text-xs px-2 py-1 rounded mr-2 ${
                            assignment.status === "Assigned"
                              ? "bg-blue-200 text-blue-800"
                              : assignment.status === "In Progress"
                              ? "bg-purple-200 text-purple-800"
                              : assignment.status === "Not Passed"
                              ? "bg-red-200 text-red-800"
                              : assignment.status === "Pending Review"
                              ? "bg-yellow-200 text-yellow-800"
                              : assignment.status === "Completed"
                              ? "bg-green-200 text-green-800"
                              : "bg-gray-200 text-gray-800"
                          }`}>
                          {assignment.status}
                        </span>

                        {/* Overdue Tag */}
                        {overdueChecker(assignment.dueDate) ? (
                          <span className="text-xs px-2 py-1 rounded bg-red-200 text-red-800">Overdue</span>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        )}

        {showProgressionModal && selectedAssignment && (
          <ProgressionModal
            isOpen={showProgressionModal}
            onClose={() => setShowProgressionModal(false)}
            assignment={selectedAssignment}
            modules={availableModules}
          />
        )}
      </div>
    </div>
  )
}
