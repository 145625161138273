import React, { useEffect, useState, useMemo, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { NotificationContext } from "../../contexts/notificationContext"
import { GetAssignmentsByManager, GetAssignmentsByUser } from "../../api/routes"
import { useStores } from "../../models/root-store"
import { observer } from "mobx-react-lite"
import DropdownSelector from "../../components/LearningCenter/dropdown"
import { toJS } from "mobx"
import { Kanban } from "../../components/Inbox/kanban"
import EnterButton from "../../components/Buttons/enterButton"
import { MagnifyingGlassIcon } from "@heroicons/react/16/solid"
import InboxSkeleton from "../../components/Inbox/skeletons"


const InboxPage = observer(() => {
  const navigate = useNavigate()
  const { userStore, teamStore, moduleListStoreV2 } = useStores()
  const [assignments, setAssignments] = useState([])
  const [loading, setLoading] = useState(true)

  // Admin manager dropdown
  const [selectedManager, setSelectedManager] = useState(null)

  // Modal
  const [showProgressionModal, setShowProgressionModal] = useState(false)
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [modulesForCurrentlySelectedAssignment, setModulesForCurrentlySelectedAssignment] = useState([])

  // Available data
  const [availableModules, setAvailableModules] = useState([])
  const [availableManagers, setAvailableManagers] = useState([])

  // Filters (only applies to admin and manager)
  const [filterUserId, setFilterUserId] = useState("")
  const [filterType, setFilterType] = useState("")
  const [filterTitle, setFilterTitle] = useState("")
  const [filterOverdue, setFilterOverdue] = useState(false)
  const [filterPersonalAssignments, setFilterPersonalAssignments] = useState(false)
  const [personalAssignmentsFetched, setPersonalAssignmentsFetched] = useState(false)

  const isAdmin = userStore.user.type === "Admin"
  const isManager = userStore.user.type === "Manager"

  const { triggerNotification } = useContext(NotificationContext)

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true)

        // Fetch team if we have not already done so, for admins only
        if (!teamStore.teamInfo && isAdmin | isManager) {
          await teamStore.getTeam(
            userStore.user.userId,
            userStore.user.type,
            userStore.user.company,
            userStore.user.userId,
          )
        }

        // Fetch modules info at company scope if not already loaded
        if (!moduleListStoreV2.userModules ?.moduleList) {
          await moduleListStoreV2.getModules(userStore.user.company, userStore.user.userId)
        }

        // Get the list of team members and managers
        const teamList = toJS(teamStore.teamInfo ?.team) || []
        const availableManagers = teamList.filter(teamMember => teamMember.type === "Manager")
        const availableModules = toJS(moduleListStoreV2.userModules ?.moduleList) || []

        setAvailableModules(availableModules)
        setAvailableManagers(availableManagers)

        // Determine managerId to fetch assignments
        let managerId
        if (isAdmin) {
          managerId = selectedManager ?.userId || availableManagers[0] ?.userId
        } else if (isManager) {
          managerId = userStore.user.userId
        } else {
          // If user is neither Admin nor Manager, use that user's manager
          managerId = userStore.user.managerId
        }

        if (!managerId) {
          triggerNotification(
            "error",
            "No team(s) to view assignments for",
            "You can still view your own if you click on 'Show personal assignments'",
          )
          // throw new Error("No manager selected to fetch assignments.")
        } else {
          const allAssignments = await GetAssignmentsByManager(managerId, userStore.user.userId)

          if (allAssignments.kind !== "ok") {
            throw new Error("Assignment fetch failed")
          }
          setAssignments(allAssignments.data || [])
        }
      } catch (error) {
        console.error("Failed to fetch data:", error)
        triggerNotification("error", "Error fetching assignments")
      } finally {
        setLoading(false)
      }
    }

    fetchAllData()
  }, [selectedManager])

  const handleManagerChange = event => {
    const selected = availableManagers.find(manager => manager.userId === event.target.value)
    setSelectedManager(selected)
  }

  const fetchPersonalAssignments = async () => {
    if (!personalAssignmentsFetched) {
      setLoading(true)
      const res = await GetAssignmentsByUser(userStore.user.userId)

      if (res.kind === "error") {
        triggerNotification("error", "Could not load personal assignments")
      } else {
        setAssignments(currAssignments => [...currAssignments, ...res.data])
        console.log([...assignments, ...res.data])
      }
      setLoading(false)
    } else {
      console.log("Personal assignments already fetched")
    }
  }

  const overdueChecker = dueDate => {
    const stringToDate = new Date(dueDate)
    const epochDueDate = stringToDate.getTime()
    const today = new Date().toDateString()
    const epochToday = new Date(today).getTime()
    return epochToday > epochDueDate
  }

  // ---- Build the user filter list based on user type ----
  const teamList = toJS(teamStore.teamInfo ?.team) || []

  // If Admin, filter by selectedManager, else if Manager, filter by self, else an empty array
  // Because for an individual, we don't show the user filter anyway.
  let userList = []
  if (isAdmin) {
    if (selectedManager ?.userId) {
      userList = teamList.filter(member => member.managerId === selectedManager.userId)
    }
  } else if (isManager) {
    userList = teamList.filter(member => member.managerId === userStore.user.userId)
  }

  // ---- FILTERED ASSIGNMENTS ----
  const filteredAssignments = useMemo(() => {
    let filtered = [...assignments]

    // If the user is neither admin nor manager, we only show assignments for the current user.
    if (!isAdmin && !isManager) {
      filtered = filtered.filter(a => a.userId === userStore.user.userId)
    } else {
      // If Admin/Manager, apply user filter
      if (filterPersonalAssignments) {
        filtered = filtered.filter(a => a.userId === userStore.user.userId)
        setPersonalAssignmentsFetched(true)
      } else {
        filtered = filtered.filter(a => a.userId !== userStore.user.userId)
      }

      if (filterUserId) {
        filtered = filtered.filter(a => a.userId === filterUserId)
      }
    }

    // Filter by assignment type (Activity, SkillChallenge, LearningPath)
    if (filterType) {
      filtered = filtered.filter(a => a.type === filterType)
    }

    // Filter by assignment name (title)
    if (filterTitle) {
      filtered = filtered.filter(a => a.displayName.toLowerCase().includes(filterTitle.toLowerCase()))
    }

    // Filter by overdue
    if (filterOverdue) {
      filtered = filtered.filter(a => overdueChecker(a.dueDate))
    }

    return filtered
  }, [assignments, isAdmin, isManager, filterUserId, filterType, filterTitle, filterOverdue, filterPersonalAssignments])

  // Group assignments into Kanban columns from the *filtered* list
  const groupedAssignments = useMemo(() => {
    return {
      Assigned: filteredAssignments.filter(assignment => ["Assigned", "Not Passed"].includes(assignment.status)),
      PendingReview: filteredAssignments.filter(assignment =>
        ["In Progress", "Pending Review"].includes(assignment.status),
      ),
      Completed: filteredAssignments.filter(assignment => assignment.status === "Completed"),
    }
  }, [filteredAssignments])

  // Kanban column configurations
  const columnConfig = [
    { title: "Assigned", key: "Assigned" },
    { title: "Pending Review", key: "PendingReview" },
    { title: "Completed", key: "Completed" },
  ]

  // ---- HANDLERS ----
  const handleAssignmentClick = assignment => {
    setSelectedAssignment(assignment)
    if (assignment.type === "LearningPath") {
      setModulesForCurrentlySelectedAssignment(
        availableModules.filter(module => Object.keys(assignment.progress.moduleStatuses).includes(module.moduleId)),
      )
      setShowProgressionModal(true)
    } else {
      const targetModule = availableModules.find(module => module.moduleId === assignment.moduleId)
      if (assignment.status == "Pending Review" || assignment.status == "Completed") {
        const latestAttempt = assignment.attempts[assignment.attempts.length - 1]
        if (latestAttempt) {
          navigate(`/moduleAnalysisPage/${targetModule.moduleId}/${assignment.userId}/${latestAttempt.attemptId}`)
        } else {
          navigate(`/assignment/${targetModule.moduleId}`)
        }
      } else {
        if (targetModule) {
          navigate(`/assignment/${targetModule.moduleId}`)
        } else {
          triggerNotification("error", "Module is still being created", "Refresh the page and try again")
        }
      }
    }
  }

  const handlePersonalAssignmentsSelection = e => {
    fetchPersonalAssignments()
    setFilterPersonalAssignments(e.target.checked)
  }

  if (loading) {
    return <InboxSkeleton></InboxSkeleton>
  }

  return (
    <div className="px-6 w-full">
      {/* PAGE HEADER */}
      <header className="border-b border-gray-200 pb-5 sm:pb-0 mt-10">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-base font-semibold leading-6 text-gray-900">Inbox</h1>
            <h1 className="text-xs leading-6 text-gray-400">
              {isAdmin
                ? "View your organization's progress"
                : isManager
                  ? "View your team's progress"
                  : "View your progress"}
            </h1>
          </div>
          <div className="sm:flex sm:items-center">
            {isAdmin ? (
              <EnterButton
                buttonLabel="Admin console"
                buttonFunction={() => navigate("/adminConsole/content")}></EnterButton>
            ) : null}
          </div>
        </div>
      </header>
      <div className="flex flex-row gap-x-4 items-center">



        {/* FILTER CONTROLS */}
        {/** Show filter controls only if Admin or Manager */}
        {(isAdmin || isManager) && (
          <div className="">
            <div className="flex flex-wrap gap-4 items-center">
              {/* Admin Dropdown for Manager Selection */}
              {isAdmin && (
                <div className="flex mt-4 mb-4">
                  <DropdownSelector
                    label="Manager"
                    options={availableManagers.map(manager => ({
                      value: manager.userId,
                      label: manager.name,
                    }))}
                    value={selectedManager ?.userId || ""}
                    onChange={handleManagerChange}
                  />
                </div>
              )}
              {/* Filter by user */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700">Reps</label>
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={filterUserId}
                  onChange={e => setFilterUserId(e.target.value)}>
                  <option value="">All Reps</option>
                  {userList.map(member => (
                    <option key={member.userId} value={member.userId}>
                      {member.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Filter by Type */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700">Training Type</label>
                <select
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={filterType}
                  onChange={e => setFilterType(e.target.value)}>
                  <option value="">All Types</option>
                  <option value="Activity">Activity</option>
                  <option value="SkillChallenge">Skill Challenge</option>
                  <option value="LearningPath">Learning Path</option>
                </select>
              </div>

              {/* Filter by Title (text search) */}
              <div className="flex flex-col">
                <label className="text-sm font-medium text-gray-700">Search</label>
                <div className="relative ">
                  <input
                    type="text"
                    placeholder="Search"
                    value={filterTitle}
                    onChange={(e) => setFilterTitle(e.target.value)}
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-8 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <MagnifyingGlassIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                </div> */}
                </div>
              </div>

              {/* Filter by Overdue */}
              <div className="flex items-center mt-6 sm:mt-0">
                <input
                  id="overdue"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                  checked={filterOverdue}
                  onChange={e => setFilterOverdue(e.target.checked)}
                />
                <label htmlFor="overdue" className="ml-2 text-sm font-medium text-gray-700">
                  Overdue only
              </label>
              </div>
              {/* Filter by Personal Assignments */}
              <div className="flex items-center mt-6 sm:mt-0">
                <input
                  id="personalAs"
                  type="checkbox"
                  className="h-4 w-4 text-blue-600 border-gray-300 rounded"
                  checked={filterPersonalAssignments}
                  onChange={e => handlePersonalAssignmentsSelection(e)}
                />
                <label htmlFor="personalAs" className="ml-2 text-sm font-medium text-gray-700">
                  Show personal assignments
              </label>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* KANBAN BOARD */}
      <div className="py-5">
        <Kanban
          loading={loading}
          columnConfig={columnConfig}
          groupedAssignments={groupedAssignments}
          handleAssignmentClick={handleAssignmentClick}
          overdueChecker={overdueChecker}
          showProgressionModal={showProgressionModal}
          selectedAssignment={selectedAssignment}
          availableModules={modulesForCurrentlySelectedAssignment}
          setShowProgressionModal={setShowProgressionModal}
        />
      </div>
    </div>
  )
})

export default InboxPage
