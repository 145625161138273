import React, { useState, useEffect } from 'react'
import UsageChart from "../usageChart"
import UsageSectionSkeleton from "../usageSectionSkeleton"
import { useStores } from "../../../models/root-store"
import { toJS } from "mobx"
import { CalendarDaysIcon } from "@heroicons/react/20/solid"
import { observer } from "mobx-react-lite"



const UsageSection = observer(({ userInfo }) => {

  const { usageStore } = useStores()
  const [usageStats, setUsageStats] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    const fetchUsageStats = async () => {
      if (!usageStore.usageData) {
        await usageStore.getUsage(userInfo.company, userInfo.userId)
      }

      const usageData = toJS(usageStore.usageData)

      if (usageData) {

        const fullData = fillMissingDays(usageData.loginDistribution)

        setUsageStats({
          attemptCount: usageData.attemptCount,
          savedAttemptCount: usageData.savedAttemptCount,
          savedPlaygroundRepCount: usageData.savedPlaygroundRepCount,
          loginDistribution: fullData,
        })
      }
      setLoading(false)
    }

    fetchUsageStats()
  }, [])

  function fillMissingDays(data) {
    if (!data || data.length === 0) return [];

    // Helper: parse a date string into a Date object.
    // Replacing the space with "T" helps ensure proper parsing.
    const parseDate = (timestamp) => new Date(timestamp.replace(" ", "T"));

    // Helper: format a Date object into "YYYY-MM-DD 00:00:00.000"
    const formatDate = (date) => {
      const year = date.getFullYear();
      // getMonth() returns 0-11 so add 1 and pad to two digits.
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day} 00:00:00.000`;
    };

    const result = [];
    // Get the first and last day based on your sorted data
    let currentDate = parseDate(data[0].timestamp);
    const endDate = parseDate(data[data.length - 1].timestamp);
    let dataIndex = 0;

    // Iterate from the first date to the last date
    while (currentDate <= endDate) {
      const currentDateStr = formatDate(currentDate);
      // Check if the current day exists in the data
      if (data[dataIndex] && data[dataIndex].timestamp === currentDateStr) {
        result.push(data[dataIndex]);
        dataIndex++;
      } else {
        // Insert a placeholder for missing day
        result.push({ timestamp: currentDateStr, value: "0" });
      }
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  }


  if (loading) {
    return (
      <div className=" max-w-7xl px-4 flex-1 ">
        <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <div>
            <div class="flex items-center gap-x-3">
              <h1 class="flex gap-x-3 text-lg leading-7">
                <span class="font-semibold text-grey">Usage</span>
              </h1>
            </div>
            <p class="mt-2 text-xs leading-6 text-gray-400">
              Usage of your organization
            </p>
          </div>
        </div>
        <UsageSectionSkeleton></UsageSectionSkeleton>
      </div>
    )

  }


  return (
    <div className=" max-w-7xl px-4 flex-1 ">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-lg leading-7">
              <span class="font-semibold text-grey">Usage</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Usage of your organization
          </p>
        </div>
      </div>
      <div className="max-h-sm w-fit  shadow rounded items-center flex flex-row font-semibold text-gray-400">
        <CalendarDaysIcon aria-hidden="true" className="h-6 w-5 text-gray-400" />
        <h1 className="py-2 px-2"> Last 90 days</h1>
      </div>
      <div class="flex flex-col gap-x-8 gap-y-6 py-4 h-[70vh]">
        <div class="flex gap-x-4 flex-row max-h-md h-[15vh] ">
          <div class="flex flex-1 flex-col rounded shadow ">
            <h1 className="font-semibold text-grey text-base p-2">
              Total attempts
            </h1>
            <h2 className="font-semibold text-grey text-4xl p-2 ">
              {usageStats.attemptCount || "0"}
            </h2>
            <div>

            </div>
          </div>
          <div class="flex flex-1 flex-col rounded shadow">
            <h1 className="font-semibold text-grey text-base p-2">
              Total recordings
            </h1>
            <h2 className="font-semibold text-grey text-4xl p-2">
              {usageStats.savedAttemptCount || "0"}
            </h2>
          </div>
          <div class="flex flex-1 flex-col rounded shadow">
            <h1 className="font-semibold text-grey text-base p-2">
              Total playground attempts
            </h1>
            <h2 className="font-semibold text-grey text-4xl p-2">
              {usageStats.savedPlaygroundRepCount || "0"}
            </h2>
          </div>
        </div>
        <div class="flex flex-1 flex-col rounded h-2/3 shadow">
          <h1 className="font-semibold text-grey text-base p-2">
            Daily Active Users
          </h1>
          <div className="font-semibold flex-grow w-full text-grey text-base p-2">
            <UsageChart data={usageStats.loginDistribution || []}></UsageChart>
          </div>
        </div>
      </div>
    </div>
  )
})

export default UsageSection
