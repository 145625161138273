import React, { useState, useEffect, useContext } from "react"
import TeamAnalysisTable from "../../components/TeamAnalysisPage/teamAnalysisTable"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import TeamPageSkeleton from "../../components/TeamPage/skeletons"
import { NotificationContext } from "../../contexts/notificationContext"
import { GenericAPIRequest } from "../../api/helpers"
import DropdownSelector from "../../components/LearningCenter/dropdown"
import EnterButton from "../../components/Buttons/enterButton"
import { useNavigate } from "react-router-dom"



const TeamAnalysisPage = () => {
  const { userStore, teamStore } = useStores()
  const { userId, company, type, userEmail } = userStore.user || {}
  const userType = type
  const isAdmin = userType === 'Admin'
  const teamMemberList = toJS(teamStore.getFullTeam)
  const [pageLoading, setPageLoading] = useState(true)
  const navigate = useNavigate()

  // Manager dropdown control (Admin only)
  const [managers, setManagers] = useState([])
  const [selectedManager, setSelectedManager] = useState(null)
  const [currentSelectedTeam, setCurrentSelectedTeam] = useState([])

  // Fetch all managers (Admin only)
  const fetchManagers = async () => {
    try {
      const fullTeam = toJS(teamStore.getFullTeam)
      const managers = fullTeam.filter(teamMember => teamMember.type === 'Manager')
      const validManagers = managers.map(manager => {
        return { userEmail: manager.userEmail, managerId: manager.userId, managerName: manager.name }
      })
      console.log(`Valid managers: ${validManagers}`)

      return validManagers
    } catch (error) {
      console.error("Error fetching managers:", error)
      return []
    }
  }

  // Fetch users under a manager
  const fetchTeamUsers = async (managerObj) => {
    try {
      const fullTeam = toJS(teamStore.getFullTeam)
      const managerTeam = fullTeam.filter(teamMember =>
        teamMember.managerEmail === managerObj.userEmail
        && teamMember.type != 'Manager'
        && teamMember.type != 'Admin')

      const teamCount = managerTeam.length === 0 ? 1 : managerTeam.length
      const requestedManagerId = userType === 'Admin' ? managerObj.managerId : managerObj.userId

      const response = await GenericAPIRequest("DataIntegration",
        "get",
        `/teamAnalysisPage`,
        {},
        {
          managerId: requestedManagerId,
          type: userType,
          email: userEmail,
          managerEmail: managerObj.userEmail,
          user_id: userStore.user.userId,
          teamCount
        }, {})

      const competencyProfiles = response.competencyProfiles
      const managerTeamSalesforceData = response.managerTeamSalesforceData
      const salesActivities = response.salesActivities

      const lookup = managerTeam.reduce((acc, item) => {
        acc[item.userEmail] = { ...item };
        return acc;
      }, {});

      managerTeamSalesforceData.forEach(item => {
        if (lookup[item.userEmail]) {
          lookup[item.userEmail] = { ...lookup[item.userEmail], ...item, competencyProfile: true, salesActivities: [] };
        }
      });

      competencyProfiles.forEach(item => {
        if (lookup[item.userEmail]) {
          lookup[item.userEmail] = { ...lookup[item.userEmail], ...item };
        }
      });

      salesActivities.forEach(item => {
        if (lookup[item.userEmail]) {
          lookup[item.userEmail].salesActivities.push(item)
        }
      });


      const mergedList = Object.values(lookup);
      setCurrentSelectedTeam(mergedList)



    } catch (error) {
      console.error("Error fetching team users:", error)
    }
  }

  const fetchTeam = async () => {
    await teamStore.getTeam(userId, type, company, userId)
  }

  useEffect(() => {

    const initialize = async () => {
      setPageLoading(true)

      const email = userStore.user.userEmail
      const userType = userStore.user.type
      const managerEmail = userType === "Manager" ? email : userStore.user.managerEmail
      await fetchTeam()

      let managerToUse = null
      if (userType === "Admin") {
        const managersList = await fetchManagers()
        if (managersList && managersList.length > 0) {
          setManagers(managersList)
          managerToUse = managersList[0]
          setSelectedManager(managerToUse)
        } else {
          console.warn("No managers available.")
          setPageLoading(false)
          return
        }
      } else if (userType === "Manager") {
        managerToUse = { userId, userEmail }
        setSelectedManager(managerToUse)
      }

      await fetchTeamUsers(managerToUse)

      setPageLoading(false)
    }

    initialize()




  }, [])

  // Handle manager change (Admin only)
  const handleManagerChange = async event => {
    const newManagerEmail = event.target.value
    const managerObj = managers.find(member => member.userEmail === newManagerEmail)
    setSelectedManager(managerObj)
    console.log(managerObj)
    setPageLoading(true)

    await fetchTeamUsers(managerObj)



    setPageLoading(false)
  }


  if (pageLoading) {
    return <TeamPageSkeleton />
  }

  return (
    <>
      <div className="w-full pt-8">

        <div className="px-4 sm:px-6 lg:px-8 w-full">
          <div className="sm:flex sm:items-center justify-betweens">
            <div className="sm:flex-auto">
              <h1 className="text-base font-semibold leading-6 text-gray-900">Team</h1>
              <p className="mt-2 mb-4 text-sm text-gray-700">Ensure your reps are pacing towards goals</p>
            </div>
            <div className="sm:flex sm:items-center">
              {isAdmin ? (
                <EnterButton
                  buttonLabel="Admin console"
                  buttonFunction={() => navigate("/adminConsole/content")}></EnterButton>
              ) : null}
            </div>
          </div>
          {userType === "Admin" ? (
            <div className="sm:flex sm:items-center space-x-4">
              <DropdownSelector
                label="Manager"
                options={managers.map(managerObj => ({ label: managerObj.managerName, value: managerObj.userEmail }))}
                value={selectedManager ?.userEmail || ""}
                onChange={handleManagerChange}
              />
            </div>
          ) : null}

          <TeamAnalysisTable
            teamMemberList={currentSelectedTeam}
            pageLoading={pageLoading}
          />

        </div>
      </div>
    </>
  )
}
export default observer(TeamAnalysisPage)
