import React, { useState } from "react"
import Avatar from "../Global/avatar"
import AnalysisDrawer from "./analysisDrawer"
import EmptyState from "../../components/Global/emptyState"
import InfoToolTip from "../TeamAnalysisPage/infoToolTip"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

const infoToolTipContent = `High Performer = “Has hit quota in the most recent period”
High Potential = “Has missed quota in the most recent period but hit it the period before"
Star Talent = “Has hit quota 2 periods in a row”
At Risk = “Has missed quota 2 periods in a row”`

const TeamAnalysisTable = ({ teamMemberList, pageLoading }) => {
  const [analysisDrawerOpen, setAnalysisDrawerOpen] = useState(false)
  const [analysisDrawerData, setAnalysisDrawerData] = useState({})

  const compProfileData = teamMemberList.filter(member => member.competencyProfile)
  const noAgentsForAnyoneOnTeam = compProfileData.length === 0

  if (noAgentsForAnyoneOnTeam) {
    return <div class="mt-12">
      <EmptyState
        header="No agent cluster for this team"
        subHeader="Admins must build an agent cluster for this team to enable Pare agent clusters"
        removeButton={true}
        icon="agent"></EmptyState>
    </div>
  }
  return (
    <div className="mt-8 flow-root overflow-x-auto">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
          {pageLoading ? (
            <div className="flex-1 px-8 flex flex-row content-center justify-center">
              <l-grid size="120" speed="1.4" color="#3045FF" className="mt-50" />
            </div>
          ) : (
              <>
                <table className="min-w-[800px] divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 flex flex-row text-left text-sm font-semibold text-gray-900 items-center gap-x-2">

                        <label>Category</label>
                        <InfoToolTip content={infoToolTipContent}>
                          <InformationCircleIcon aria-hidden="true" className="size-4 text-gray-400" />
                        </InfoToolTip>
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Top rep similarity
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Current insights generated
                      </th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">

                    {compProfileData.map((member, idx) => (
                      <UserRow
                        key={member.userEmail}
                        user={member}
                        setAnalysisDrawerOpen={setAnalysisDrawerOpen}
                        setAnalysisDrawerData={setAnalysisDrawerData}

                      />
                    ))}
                  </tbody>
                </table>
                <AnalysisDrawer
                  open={analysisDrawerOpen}
                  setOpen={setAnalysisDrawerOpen}
                  analysisDrawerData={analysisDrawerData}></AnalysisDrawer>
              </>
            )}
        </div>
      </div>
    </div>
  )
}

const UserRow = ({ user, setAnalysisDrawerOpen, setAnalysisDrawerData }) => {

  const competencies = JSON.parse(user ?.perCompetencyJson || "{}")
  const handleAnalysisDrawerOpen = () => {
    setAnalysisDrawerOpen(true)
    setAnalysisDrawerData({
      name: user.name,
      overallAverageSimilarity: user.overallAverageSimilarity,
      competencies,
      distributionDistance: user.distributionDistance,
      group: user.quadrant,
      salesActivities: user.salesActivities,
      report: user.report
    })

  }

  const overallSim = Math.round(user.overallAverageSimilarity * 10000) / 100
  const insightsGeneratedCount = user.salesActivities.length ?? 0

  return (
    <tr className="hover:bg-gray-100 cursor-pointer"
      onClick={handleAnalysisDrawerOpen}>
      <td className="whitespace-nowrap flex items-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        <Avatar name={user.name} profilePicture={user.profilePicture} size="lg" />
        <div className="ml-4">
          <div>{user.name}</div>
          <div className="mt-1 text-gray-500">{user.email}</div>
        </div>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {user.quadrant || "N/A"}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {`${overallSim}%`}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {insightsGeneratedCount}
      </td>
    </tr>

  )
}

export default TeamAnalysisTable
