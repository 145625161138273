import React from "react"
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../../@shadcn_components/ui/breadcrumb"
import AttemptThumbnail from "../AttemptThumbnail/AttemptThumbnail"
import BackButton from "../../components/Buttons/backButton"
import ActivityTeamViewAttemptsSkeleton from "./moduleAttemptsSkeleton"

const ModuleAttempts = ({
  moduleInfo,
  attemptedName,
  attemptedUserId,
  handleBackToTeamViewClick,
  attemptsLoading,
  attemptData,
  attemptsArray,
}) => {
  const fancyTimeFormat = (duration) => {
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;

    return ret;
  }
  return (
    <div>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/inboxPage">Inbox</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{`${attemptedName} Attempts`}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div>
        {/* Header showing attempt stats */}
        <header className="pt-4 pb-6">
          <div className="flex flex-col items-start justify-between gap-x-8 gap-y-4 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8 rounded">
            <div>
              <div className="flex items-center gap-x-3">
                <div className="flex-none rounded-full bg-yellow-400/10 p-1 text-yellow-400">
                  <div className="h-2 w-2 rounded-full bg-current"></div>
                </div>

                <h1 className="flex gap-x-3 text-base leading-7">
                  <span className="font-semibold text-grey">{moduleInfo.displayName}</span>
                </h1>
              </div>
              <p className="mt-2 text-xs leading-6 text-gray-400">{`Attempts by ${attemptedName}`}</p>
            </div>
            <BackButton
              buttonLabel="Back to team view"
              buttonFunction={handleBackToTeamViewClick}>
            </BackButton>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
            <div className="border-t border-white/5 px-4 py-6 sm:px-6 lg:px-8">
              <p className="text-sm font-medium leading-6 text-gray-400 flex">Passed</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-grey">
                  {attemptData.passed ? "Yes" : "No"}
                </span>
              </p>
            </div>
            <div className="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
              <p className="text-sm font-medium leading-6 text-gray-400 flex">Average Attempt Time</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-grey">
                  {fancyTimeFormat(Math.floor(attemptData.sum / attemptData.num) || 0)}
                </span>
                <span className="text-sm text-gray-400">secs</span>
              </p>
            </div>
            <div className="border-t border-white/5 px-4 py-6 sm:px-6 lg:border-l lg:px-8">
              <p className="text-sm font-medium leading-6 text-gray-400 flex">Last Attempt Date</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-grey">
                  {attemptData.lastAttemptDate ? new Date(attemptData.lastAttemptDate).toLocaleDateString() : "N/A"}
                </span>
              </p>
            </div>
            <div className="border-t border-white/5 px-4 py-6 sm:border-l sm:px-6 lg:px-8">
              <p className="text-sm font-medium leading-6 text-gray-400 flex">Total Attempts</p>
              <p className="mt-2 flex items-baseline gap-x-2">
                <span className="text-4xl font-semibold tracking-tight text-grey">{attemptData.num || 0}</span>
              </p>
            </div>
          </div>
        </header>

        {/* Attempts list or loading spinner */}
        {attemptsLoading ? (
          <ActivityTeamViewAttemptsSkeleton />
        ) : (
            <div className="w-full flex flex-row flex-wrap">
              {attemptsArray
                .sort((a, b) => b.attemptTime - a.attemptTime)
                .map((x, i) => (
                  <AttemptThumbnail
                    key={x ?.attemptId || i}
                    attempt={x}
                    order={i + 1}
                    attemptedUserId={attemptedUserId}
                    moduleId={moduleInfo.moduleId}
                  />
                ))}
            </div>
          )}
      </div>
    </div>
  )
}

export default ModuleAttempts
