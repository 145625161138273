import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import 'chart.js/auto';

// Register Chart.js components and plugins
Chart.register(...registerables);

const BarChartComparison = ({ barChartLabels, barChartData, repName }) => {

  // Prepare the chart data using useMemo for efficiency
  const chartData = useMemo(() => {

    // Handle the case when averageCompetencyScores is null or undefined
    if (!barChartData) {
      return {
        labels: [], // Empty labels
        datasets: [], // No datasets
      };
    }

    // Map the competency names to the values for the current user and the team
    const userScores = barChartData.map(x => x.avgSimilarity)
    const icpScores = barChartData.map(x => 1);

    return {
      labels: barChartLabels, // X-axis labels
      datasets: [
        {
          label: 'Top Rep Benchmark',
          data: icpScores, // Team average competency scores
          backgroundColor: 'rgba(191, 219, 254, 0.6)', // Light blue color for team
          borderColor: 'rgba(191, 219, 254, 1)', // Blue border for team
          borderWidth: 1,
          borderRadius: 5, // Rounded corners
          barThickness: 5, // Thicker bars
        },
        {
          label: `${repName}'s scores`,
          data: userScores,
          backgroundColor: 'rgba(37, 99, 235, 0.9)', // Strong blue color for user
          borderColor: 'rgba(37, 99, 235, 1)', // Strong blue border
          borderWidth: 1,
          borderRadius: 5, // Rounded corners
          barThickness: 5, // Thicker bars
        },
      ],
    };
  }, [barChartData]);

  // Bar chart options for customization
  const options = {
    indexAxis: 'y', // Make the chart horizontal
    scales: {
      x: {
        beginAtZero: true, // Start the x-axis at 0
        max: 1, // Assuming scores are out of 10
      },
      y: {
        grid: {
          display: false,
        },
        barPercentage: 0.1,
        categoryPercentage: 0.8,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true, // Use dots for legend points
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          }
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 10,
        displayColors: false, // Remove the color box in tooltip
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BarChartComparison;
