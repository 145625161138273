import React, { useState, useContext } from "react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { GenericAPIRequest } from "../../api/helpers"
import { useStores } from "../../models/root-store"
import { NotificationContext } from "../../contexts/notificationContext"
import { SparklesIcon } from '@heroicons/react/16/solid'
import AgentTooltip from "../HomePage/agentTooltip"
import SourceList from '../HomePage/sourceList'



const TaskPopUp = ({ header, leftButtonLabel, leftButtonFunction, inspectData }) => {
  const { userStore, recModuleStore } = useStores()
  const userId = userStore.user.userId
  const { triggerNotification } = useContext(NotificationContext)
  const [assigning, setAssigning] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const opportunityName = inspectData.targetedOppName
  const recommendedModuleDisplayName = inspectData.displayName
  const objective = inspectData.objective
  const personaSummary = inspectData.persona.summary
  const contextSummary = inspectData.context.summary
  const rubricContent = inspectData.rubric.content
  const sources = inspectData.sources
  const callType = inspectData.callType
  const links = inspectData.links

  const deleteRecommendation = async () => {
    setDeleting(true)
    const res = await recModuleStore.removeRec(inspectData.PK, inspectData.SK, userId)
    if (res) {
      triggerNotification('success', "Recommendation deleted!")
    } else {
      triggerNotification('error', "There was an issue deleting the recommendation")
    }
    setDeleting(false)
    leftButtonFunction()
  }

  const assignRecommendation = async () => {
    setAssigning(true)
    const res = await recModuleStore.assignRec(inspectData, userId)
    if (res) {
      triggerNotification('success', "Recommendation assigned!")
    } else {
      triggerNotification('error', "There was an issue assigning the recommendation")
    }
    setAssigning(false)
    leftButtonFunction()
  }

  return (<div class="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">

    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

        <div class="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all w-6/12 ">
          <XMarkIcon
            onClick={leftButtonFunction}
            aria-hidden="true"
            className="size-10 text-black cursor-pointer pl-4" />
          <div class="flex mt-3 sm:mt-5 justify-between items-center">

            <h3 class="px-6 text-base font-semibold leading-6 text-gray-900" id="modal-title">{recommendedModuleDisplayName}</h3>
            <div className="ml-auto flex space-x-2 text-gray-300">
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-1.5 text-sm font-semibold shadow-sm hover:text-red-400 hover:border-red-400 border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={deleting ? null : deleteRecommendation}
              >
                {deleting ? "Dismissing..." : "Dismiss"}
                <XMarkIcon aria-hidden="true" className="size-4 " />
              </button>
              <button
                type="button"
                className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={!assigning ? assignRecommendation : null}
              >
                {!assigning ? "Assign" : "Assigning.."}
              </button>
            </div>
          </div>
          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Description: `}
                  </span>
                  {inspectData.description}</p>
              </dt>
            </div>
            <div className="flex justify-between gap-x-4 py-3 items-center">
              <AgentTooltip header={`Why is this recommended for ${inspectData.userName.split(' ')[0]}?`} content={inspectData.recommendationWhy} position="bottom">
                <div class="flex cursor-pointer">
                  <SparklesIcon aria-hidden="true" className="mr-4 size-6 text-pareBlue hover:text-indigo-600" />
                  <dt className="text-pareBlue hover:text-indigo-600 font-semibold">{`Why is this recommended for ${inspectData.userName.split(' ')[0]}?`}</dt>
                </div>
              </AgentTooltip>
              <SourceList
                sources={sources}
                opportunityName={opportunityName}></SourceList>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Call type: `}
                  </span>
                  {callType}</p>
              </dt>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Objective: `}
                  </span>
                  {objective}</p>
              </dt>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Persona summary: `}
                  </span>
                  {personaSummary}</p>
              </dt>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Scenario background: `}
                  </span>
                  {contextSummary}</p>
              </dt>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600" >
                  <span className="text-gray-900 font-semibold">
                    {`Links: `}
                  </span>
                  {"N/A"}</p>
              </dt>
            </div>
            <div className="flex gap-x-4 py-3">
              <dt >
                <p className="text-gray-600 whitespace-pre-line" >
                  <span className="text-gray-900 font-semibold">
                    {`Rubric: `}
                  </span>
                  {rubricContent}</p>
              </dt>
            </div>
          </dl>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
            <button type="button" class="inline-flex w-full justify-center rounded-md bg-pareBlue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-pareBlue sm:col-start-2" onClick={!assigning ? assignRecommendation : null}>{assigning ? 'Assigning...' : 'Assign'}</button>
            <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0" onClick={leftButtonFunction}>{leftButtonLabel}</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default TaskPopUp