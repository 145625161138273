// HomePage.jsx
import React, { useEffect, useState, useContext } from "react"
import { Auth, API } from "aws-amplify"
import TeamPageSkeleton from "../../components/TeamAnalysisPage/skeletons"
import ChecklistDrawer from "../../components/Homedash/checklistDrawer"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import ChecklistAlert from "../../components/Homedash/checklistAlert"
import { useNavigate } from "react-router-dom"
import EnterButton from "../../components/Buttons/enterButton"
import TaskDashboard from "../../components/HomePage/taskDashboard"
import TaskPopUp from "../../components/PopUps/TaskPopUp"
import RepDashboard from "../../components/HomePage/repDashboard"
import { NotificationContext } from "../../contexts/notificationContext"



const HomePage = observer(() => {
  const { userStore, recModuleStore } = useStores()
  const userId = userStore.user.userId
  const email = userStore.user.userEmail
  const name = userStore.user.name
  const userType = userStore.user.type
  const company = userStore.user.company
  const firstName = userStore.user.firstName
  const managerId = userStore.user.managerId
  const isAdmin = userType === "Admin"
  const isManager = userType === "Manager"
  const isRep = userType !== "Admin" && userType !== "Manager"
  const navigate = useNavigate()
  const { triggerNotification } = useContext(NotificationContext)


  // State variables
  const [isLoading, setIsLoading] = useState(true)
  const [checklistData, setChecklistData] = useState(null)
  const [isChecklistOpen, setIsChecklistOpen] = useState(false)
  const [showTaskInspect, setShowTaskInspect] = useState(false)
  const recommendationList = recModuleStore.getRecList()
  const [recommendations, setRecommendations] = useState(recommendationList)
  const [inspectData, setInpectData] = useState(null)

  //Fetch reccomendations
  const fetchRecommendations = async (managerId) => {
    try {

      await recModuleStore.getRecs(managerId, userId, userType)

      setRecommendations(recModuleStore.getRecList())
    } catch (error) {
      console.error("Error fetching team data:", error)
    }
    return []
  }

  // Fetch data for a team
  const fetchData = async () => {
    try {
      const id = isManager ? userId : managerId
      await fetchRecommendations(id)

    } catch (error) {
      console.error("Error fetching team data:", error)
    }
  }



  const deleteRecommendation = async (pk, sk) => {
    const res = await recModuleStore.removeRec(pk, sk, userId)

    if (res) {
      triggerNotification('success', "Recommendation deleted!")
    } else {
      triggerNotification('error', "There was an issue deleting the recommendation")
    }
  }

  const assignRecommendation = async (rec) => {
    const res = await recModuleStore.assignRec(rec, userId)
    if (res) {
      triggerNotification('success', "Recommendation assigned!")
    } else {
      triggerNotification('error', "There was an issue assigning the recommendation")
    }
  }


  // Fetch checklist data
  const fetchChecklist = async () => {
    try {
      const checklistInfo = await API.get("DataIntegration", "/checklistInfo", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        queryStringParameters: {
          userId: userId,
          userType: userType,
          company: company,
          user_id: userId,
        },
      })

      // Set checklist data based on userType
      if (userType === "Admin") {
        const tasks = [
          {
            id: 1,
            name: "Set up Call Intelligence Integration",
            status: checklistInfo.hasCallIntelligence,
            href: "/settings",
          },
          {
            id: 2,
            name: "Set up CRM Integration",
            status: checklistInfo.hasCrm,
            href: "/settings",
          },
          {
            id: 3,
            name: "Invite a team manager to the platform",
            status: checklistInfo.hasInvitedManager,
            href: "/managerTeam",
          },
        ]
        setChecklistData(tasks)
      } else if (userType === "Manager") {
        const tasks = [
          {
            id: 1,
            name: "Invite team members to the platform",
            status: checklistInfo.hasInvitedUsers,
            href: "/managerTeam",
          },
          // Add other manager-specific tasks if any
        ]
        setChecklistData(tasks)
      }
    } catch (error) {
      console.error("Error fetching checklist data:", error)
    }
  }



  const inspectTask = (data) => {
    setShowTaskInspect(true)
    setInpectData(data)
  }

  // Initialize data based on user type
  useEffect(() => {
    if (!userStore.user) {
      return
    }

    const initialize = async () => {
      if (!isRep) {
        await fetchData()
      }
      setIsLoading(false)
    }

    initialize()
  }, [userStore.user])



  const handleOpenChecklist = () => {
    setIsChecklistOpen(true)
  }

  if (isLoading) {
    return <TeamPageSkeleton />
  }
  return (
    <div className="w-full h-full p-8">
      {/* Breadcrumb */}
      <div className="mb-2 flex flex-row justify-between">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">{`Hello, ${firstName} 👋`}</h1>
            <p className="mt-2 text-sm text-gray-700">See what’s happening and where to spend your time</p>
          </div>
        </div>
        <div className="sm:flex sm:items-center">
          {isAdmin ? (
            <EnterButton
              buttonLabel="Admin console"
              buttonFunction={() => navigate("/adminConsole/content")}></EnterButton>
          ) : null}
        </div>
      </div>

      {/* Checklist Drawer */}
      {isChecklistOpen && (
        <ChecklistDrawer isOpen={isChecklistOpen} setIsOpen={setIsChecklistOpen} checklistData={checklistData} />
      )}

      {/* Checklist Alert */}
      {checklistData && checklistData.some(task => task.status !== "Complete") && (
        <div className="mb-4">
          <ChecklistAlert onDetailsClick={handleOpenChecklist} />
        </div>
      )}

      {/* User Greeting */}
      {isRep ? <RepDashboard></RepDashboard> :
        <TaskDashboard
          inspectTask={inspectTask}
          recommendations={recommendations}
          setInpectData={setInpectData}
          deleteRecommendation={deleteRecommendation}
          assignRecommendation={assignRecommendation}
          company={company}></TaskDashboard>}

      {showTaskInspect ? <TaskPopUp
        header="Inspect Module Recommendation"
        leftButtonFunction={() => setShowTaskInspect(false)}
        leftButtonLabel="Exit"
        rightButtonFunction={() => setShowTaskInspect(false)}
        rightButtonLabel="Assign"
        inspectData={inspectData}
      ></TaskPopUp> : null}
    </div>
  )
})

export default HomePage
