import React, { useContext } from "react"
import { PencilSquareIcon, SparklesIcon, TrashIcon } from "@heroicons/react/20/solid"
import { NotificationContext } from "../../contexts/notificationContext"


const AgentTableEntry = ({ agentInfo }) => {

  const { triggerNotification } = useContext(NotificationContext)

  const handleEditClick = () => {
    triggerNotification('error', "Editing isn't permitted yet")
  }

  const handleDeleteClick = () => {
    triggerNotification('error', "Deleting isn't permitted yet")
  }

  const repScopeCount = agentInfo ?.agentMap.size ?? 0
  return (
    <tr key={`${agentInfo.agentId}`} className="flex flex-row justify-between border-b border-gray-100">
      <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0 hover:">
        <div className="flex items-center">
          <SparklesIcon aria-hidden="true" className="h-5 w-5 text-pareBlue mr-1" />
          <div className="ml-4 flex flex-col">
            <div className="font-medium text-gray-900">{`${agentInfo.repScope} agent cluster`}</div>
            <div className="mt-1 text-gray-500">{`${repScopeCount} reps`}</div>
          </div>
          <span className={`inline-flex items-center rounded-md ml-6 px-2 py-1 text-xs font-medium ${agentInfo.status === 'Creating' ? 'text-yellow-800 bg-yellow-100' : 'text-green-800 bg-green-100'}`}>
            {agentInfo.status}
          </span>
        </div>

      </td>
      <td className="flex flex-row items-center py-5 pr-4 text-sm font-medium">
        <PencilSquareIcon aria-hidden="true" className="h-6 w-6 text-pareBlue mr-4 cursor-pointer" onClick={handleEditClick} />
        <TrashIcon aria-hidden="true" className="h-6 w-6 text-pareBlue mr-1 cursor-pointer" onClick={handleDeleteClick} />
      </td>
    </tr>
  )
}

export default AgentTableEntry