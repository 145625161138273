import React, { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

import ChatRoomPage from "./pages/ChatPage/ChatRoomPage"
import PlaygroundChatRoomPage from "./pages/ChatPage/PlaygroundChatRoomPage"
import HomePage from "./pages/HomePage/HomePage"
// import TaskDashboard from "./pages/HomePage/TaskDashboard"
import PricingPage from "./pages/PricingPage/PricingPage"
import ModuleAnalysisPage from "./pages/ModuleAnalysisPage/ModuleAnalysisPage"
import LearningPathDetailPage from "./pages/LearningPathDetailPage"
import TermOfServicePage from "./components/TermOfServicePage/TermOfServicePage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage/PrivacyPolicyPage"
import AssignmentDetailPage from "./pages/AssignmentDetailPage/AssignmentDetailPage"

import SideBar from "./components/SideBar/SideBar"
import Home from "./tailwinds_components/app/page"
import NotFound from "./tailwinds_components/app/not-found"
import WaitlistRequest from "./pages/WaitlistRequestPage/waitlistRequest"
import SignUp from "./pages/SignupPage/signup"
import SignIn from "./pages/SigninPage/signin"
import CombinedModularSettings from "./pages/SettingsPageCombined/combined"
import UnifiedAssignemnts from "./pages/UnifiedAssignmentsPage/UnifiedAssignemnts"
import PersonaLibraryPage from "./pages/PersonaLibraryPage/PersonaLibraryPage"
import AdminConsoleSettingsPage from "./pages/AdminConsolePages/AdminConsoleSettingsPage"
import AdminConsoleContentPage from "./pages/AdminConsolePages/AdminConsoleContentPage"
import AdminConsoleReportingPage from "./pages/AdminConsolePages/AdminConsoleReportingPage"
import TeamAnalysisPage from "./pages/TeamAnalysisPage/TeamAnalysisPage"
import AdminConsoleHeaderBar from "./components/AdminConsoleHeaderBar/AdminConsoleHeaderBar"
import PlaygroundPage from "./pages/PlaygroundPage/PlaygroundPage"
import InboxPage from "./pages/InboxPage/InboxPage"


import { NotificationProvider } from "./contexts/notificationContext"

import { BrowserRouter as Router, Route, Routes, useNavigate, Outlet, useLocation } from "react-router-dom"
import { RootStoreModel, RootStoreProvider, useStores } from "./models/root-store"
import { observer } from "mobx-react-lite"

const queryClient = new QueryClient()

function App() {
  const rootStore = RootStoreModel.create({} as any)

  return (
    <QueryClientProvider client={queryClient}>
      <RootStoreProvider value={rootStore}>
        <Router>
          <MainLayout />
        </Router>
      </RootStoreProvider>
    </QueryClientProvider>
  )
}

export default App

function MainLayout() {
  const location = useLocation()
  const isAdminConsole = location.pathname.startsWith("/adminConsole")

  return (
    <main className="flex flex-col h-screen">
      <div className={`flex flex-1 ${isAdminConsole ? "flex-col" : "overflow-hidden"}`}>
        {isAdminConsole ? <AdminConsoleHeaderBar /> : <SideBar />}
        <div className="flex flex-1 flex-col">
          <div className="flex flex-1 overflow-y-auto">
            <Routes>
              {/* Unauthenticated Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/pricingpage" element={<PricingPage />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/tos" element={<TermOfServicePage />} />
              <Route path="/privacy" element={<PrivacyPolicyPage />} />
              <Route path="/waitlistRequestPage" element={<WaitlistRequest />} />
              <Route path="/notFound" element={<NotFound />} />
              {/* Authenticated Routes */}
              <Route element={<AuthWrapper />}>
                <Route
                  element={
                    <NotificationProvider>
                      <Outlet />
                    </NotificationProvider>
                  }>
                  <Route path="/homepage" element={<HomePage />} />
                  <Route path="/chatRoomPage" element={<ChatRoomPage />} />
                  <Route path="/playgroundChatRoom/:personaId/:instanceId" element={<PlaygroundChatRoomPage />} />
                  <Route path="/settings" element={<CombinedModularSettings />} />
                  <Route path="/managerTeam" element={<TeamAnalysisPage />} />
                  <Route path="/moduleAnalysisPage/:moduleId/:attemptedUserId/:attemptId" element={<ModuleAnalysisPage />} />
                  <Route path="/assignmentsPage" element={<UnifiedAssignemnts />} />
                  <Route path="/inboxPage" element={<InboxPage />} />
                  <Route path="/assignment/:moduleId" element={<AssignmentDetailPage />} />
                  <Route path="/playground" element={<PlaygroundPage />} />
                  <Route path="/personaLibrary" element={<PersonaLibraryPage />} />
                  <Route path="/adminConsole/settings" element={<AdminConsoleSettingsPage />} />
                  <Route path="/adminConsole/reporting" element={<AdminConsoleReportingPage />} />
                  <Route path="/adminConsole/content" element={<AdminConsoleContentPage />} />
                  <Route path="/learningPaths/:learningPathId" element={<LearningPathDetailPage />} />
                </Route>
              </Route>
            </Routes>
          </div>
        </div>
      </div>
      {/* <div className="flex">Footer</div> <-- placeholder for sticky footer in future */}
    </main>
  )
}

/**
 * Wrapper for all routes that require authentication. Checking `Auth.currentSession()`
 * for existing AWS session. If error is caught, user is redirected to signin. On success,
 * `authorized` is set to `true` in the AppContext. Pages can access the value like this:
 *
 * import { useAppContext } from "../../lib/appContext"
 * const { authorized } = useAppContext()
 *
 */

const LoadingScreen = () => (
  <div className="flex items-center justify-center min-h-screen w-full">
    <l-grid size="120" speed="1.4" color="#3045FF" />
  </div>
)

const AuthWrapper = observer(() => {
  const navigate = useNavigate()
  const { userStore } = useStores()

  // Loading state to track the authentication process
  const [loading, setLoading] = useState(true)

  const authenticateUser = async () => {
    try {
      if (!userStore.user) {
        const authenticated = await userStore.getUser()
        if (!authenticated) {
          navigate("/signin")
        }
      }
    } catch (error) {
      console.error("Error during authentication:", error)
      navigate("/signin") // In case of error, redirect to sign-in
    } finally {
      setLoading(false) // Stop loading after authentication is done
    }
  }

  useEffect(() => {
    authenticateUser()
  }, [])

  // Show loading screen while authenticating
  if (loading) {
    return <LoadingScreen />
  }

  // Once authenticated, render the page
  return userStore.user ? <Outlet /> : <div className="flex-1" />
})
