import React, { useState, useContext } from "react"
import { useStores } from "../../models/root-store"
import { NotificationContext } from "../../contexts/notificationContext"
import AgentTooltip from "../HomePage/agentTooltip"
import { SparklesIcon } from '@heroicons/react/16/solid'


const moduleInfoMap = {
  Description: "description",
  Objective: "objective",
  "Call Type": "callType",
  "Persona Summary": "persona.summary",
  "Scenario Background": "context.summary",
  Links: "links",
}

const ModuleDetails = ({ moduleInfo, setRubricPopUp }) => {
  const { userStore, moduleListStoreV2 } = useStores()
  const { userId, type, company } = userStore.user || {}
  const { triggerNotification } = useContext(NotificationContext)

  // Due to the nested object structure of persona and context, we need to deep-copy the fields if they are updated
  const saveModuleChange = async (updatedField, updatedValue, updatedFieldDisplayName) => {
    if (updatedField === "persona.summary") {
      await savePersonaSummary(updatedValue)
      return
    } else if (updatedField === "context.summary") {
      await saveContextSummary(updatedValue)
      return
    }

    try {
      const updatedModule = {
        ...moduleInfo,
        [updatedField]: updatedValue,
        user_id: userId,
      }

      const success = await moduleListStoreV2.updateModule(company, updatedModule.moduleId, updatedModule)

      if (success) {
        triggerNotification(
          "success",
          "Module Update Successful!",
          `The ${updatedFieldDisplayName} was successfully updated!`,
        )
      } else {
        triggerNotification("error", "Module Update Error", "Could not update this field.")
      }
    } catch (err) {
      console.error("Module Update Error:", err)
      triggerNotification("error", "Module Update Error", err ?.message || "An unknown error occurred.")
    }
  }

  const savePersonaSummary = async newSummary => {
    const updatedModule = {
      ...moduleInfo,
      persona: {
        ...moduleInfo.persona,
        summary: newSummary,
      },
      user_id: userId,
    }

    const success = await moduleListStoreV2.updateModule(company, updatedModule.moduleId, updatedModule)

    if (success) {
      triggerNotification("success", "Success!", "Persona summary updated!")
    } else {
      triggerNotification("error", "Module Update Error", "Could not update persona.")
    }
  }

  const saveContextSummary = async newSummary => {
    const updatedModule = {
      ...moduleInfo,
      context: {
        ...moduleInfo.context,
        summary: newSummary,
      },
      user_id: userId,
    }

    const success = await moduleListStoreV2.updateModule(company, updatedModule.moduleId, updatedModule)

    if (success) {
      triggerNotification("success", "Success!", "Scenario background updated!")
    } else {
      triggerNotification("error", "Module Update Error", "Could not update scenario.")
    }
  }

  return (
    <div className="mt-6 border-t border-gray-100">
      <dl className="divide-y divide-gray-100">
        {moduleInfo.isAutoGenerated ? <DetailRow
          label="Why was this recommended?"
          value={moduleInfo ?.recommendationReason}
          userType={type}
          recommendationRow={true}
        /> : null}
        <DetailRow
          label="Description"
          value={moduleInfo ?.description}
          saveModuleChange={saveModuleChange}
          userType={type}
        />
        <DetailRow
          label="Objective"
          value={moduleInfo ?.objective}
          saveModuleChange={saveModuleChange}
          userType={type}
        />
        <DetailRow label="Call Type" value={moduleInfo ?.callType} saveModuleChange={saveModuleChange} userType={type} />
        <DetailRow
          label="Persona Summary"
          value={moduleInfo ?.persona ?.summary ?? ""}
          saveModuleChange={saveModuleChange}
          userType={type}
        />
        <DetailRow
          label="Scenario Background"
          value={moduleInfo ?.context ?.summary ?? ""}
          saveModuleChange={saveModuleChange}
          userType={type}
        />
        <DetailRow
          label="Links"
          value={
            // Check for moduleInfo, confirm links is an array, and ensure it has at least one element
            Array.isArray(moduleInfo ?.links) && moduleInfo.links.length > 0
              ? moduleInfo.links.map((link, index) => (
                <button
                  key={index}
                  onClick={() => {
                    // If the link doesn’t start with http(s), prepend https
                    const url = link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`
                    window.open(url, "_blank")
                  }}
                  className="rounded-md shadow-md p-2 hover:bg-gray-200">
                  <p className="line-clamp-2 font-semibold text-md text-left">{link}</p>
                </button>
              ))
              : // If links is null, undefined, or empty, show "N/A"
              "N/A"
          }
          saveModuleChange={saveModuleChange}
          userType={type}
        />

        {setRubricPopUp && (
          <DetailRow
            label="Rubric"
            value={
              <button
                onClick={() => setRubricPopUp({ show: true, mode: "view" })}
                className="rounded-md shadow-md p-2 hover:bg-gray-200">
                <p className="line-clamp-2 font-semibold text-md text-left">Custom Rubric</p>
              </button>
            }
            setRubricPopUp={setRubricPopUp}
            userType={type}
          />
        )}
      </dl>
    </div>
  )
}

const DetailRow = ({ label, value, saveModuleChange, setRubricPopUp, userType, recommendationRow }) => {
  const [editMode, setEditMode] = useState(false)
  const moduleField = moduleInfoMap[label]
  const [currentValue, setCurrentValue] = useState(value)

  const handleInputChange = e => {
    setCurrentValue(e.target.value)
  }
  function autoResize(event) {
    const textarea = event.target
    textarea.style.height = "auto"
    textarea.style.height = `${textarea.scrollHeight}px`
  }

  const handleEditCancel = () => {
    setEditMode(false)
    setCurrentValue(value)
  }

  const handleEditClick = () => {
    if (label === "Rubric") {
      setRubricPopUp({ show: true, mode: "edit" })
    } else {
      setEditMode(true)
    }
  }

  const handleSaveClick = async () => {
    saveModuleChange(moduleField, currentValue, label)
    setEditMode(false)
  }

  if (recommendationRow) {
    return (<div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-pareBlue">
        <AgentTooltip header={`Why is this recommended?`} content={value} position="right">
          <div class="flex cursor-pointer">
            <SparklesIcon aria-hidden="true" className="mr-4 size-6 text-pareBlue hover:text-indigo-600" />
            <dt className="text-pareBlue hover:text-indigo-600 font-semibold">{`Why is this recommended?`}</dt>
          </div>
        </AgentTooltip>
      </dt>
    </div>)
  }

  if (userType !== "Admin" && userType !== "Manager") {
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
        <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
        <dd className="mt-1 flex text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0 whitespace-pre-line">
          {value || "N/A"}
        </dd>
      </div>
    )
  }

  return (
    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
      <dt className="text-sm/6 font-medium text-gray-900">{label}</dt>
      <dd className="mt-1 flex text-sm/6 text-gray-700 sm:col-span-2 sm:mt-0">
        {editMode ? (
          <>
            <span className="grow">
              <textarea
                value={currentValue}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-pareBlue sm:max-w-md sm:text-sm sm:leading-6 resize"
                onChange={handleInputChange}
                onSelect={autoResize}></textarea>
            </span>
            <span className="ml-4 shrink-0">
              <button
                className="rounded-md bg-white font-medium text-pareBlue hover:text-pareBlue mr-4"
                onClick={handleSaveClick}>
                Save
              </button>
              <button
                className="rounded-md bg-white font-medium text-red-600 hover:text-red-500"
                onClick={handleEditCancel}>
                Cancel
              </button>
            </span>
          </>
        ) : (
            <>
              <span className="grow whitespace-pre-line">{currentValue}</span>
              <span className="ml-4 shrink-0">
                <button
                  type="button"
                  className="rounded-md bg-white font-medium text-pareBlue hover:text-pareBlue"
                  onClick={handleEditClick}>
                  Edit
              </button>
              </span>
            </>
          )}
      </dd>
    </div>
  )
}

export default ModuleDetails
