import React, { useState, useEffect, useContext } from 'react'
import BuildButton from '../../Buttons/buildButton';
import AgentTable from "../agentTable"
import EmptyState from "../../Global/emptyState"
import { NotificationContext } from "../../../contexts/notificationContext"
import { useStores } from "../../../models/root-store"
import { toJS } from "mobx"
import OrganizationSectionSkeleton from "../organizationSectionSkeleton"
import AgentCreationPopUp from "../agentCreationPopUp"
import { v4 as uuid } from "uuid"
import { GenericAPIRequest } from "../../../api/helpers"




const AgentsSection = ({ }) => {

  const { agentClusterStore, userStore, teamStore } = useStores()
  const { userId, company, type } = userStore.user || {}
  const agentClusters = toJS(agentClusterStore.agents ?.agentClusterList) || []
  const [agentClusterList, setAgentClusterList] = useState(agentClusters)
  const [loading, setLoading] = useState(true)
  const { triggerNotification } = useContext(NotificationContext)
  const [showAgentCreation, setShowAgentCreation] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [agentParameters, setAgentParameters] = useState({
    compProfileFreqNum: null,
    compProfileFreqUnit: null,
    quotaCutOff: null,
    quotaGoal: null,
    quotaMetricFieldName: null,
    quotaTimeFrame: null,
    repScope: null,
    salesforceObject: null,
  })
  const teamList = toJS(teamStore.teamInfo ?.team) || []
  const teamTypes = teamList.reduce((uniqueTypes, member) => {
    // Only add the type if it exists and hasn't been added yet
    if (member.type && !uniqueTypes.includes(member.type) && !["Manager", "Admin"].includes(member.type)) {
      uniqueTypes.push(member.type);
    }
    return uniqueTypes;
  }, []);


  useEffect(() => {

    const fetchAgents = async () => {
      if (!agentClusterStore.agents && company === "Tines") {
        await agentClusterStore.getAllAgentClusters(userId)
      }

      const agentClusters = toJS(agentClusterStore.agents ?.agentClusterList) || []

      setAgentClusterList(agentClusters)


    }

    const fetchTeam = async () => {
      if (!teamStore.teamInfo) {
        await teamStore.getTeam(userId, type, company, userId)
      }
    }

    const fetchData = async () => {

      // await Promise.all([fetchTeam, fetchAgents])
      await fetchTeam()
      await fetchAgents()
      setLoading(false)
    }

    fetchData()
  }, [])

  const handleAgentParametersChange = (paramLabel, paramValue) => {
    setAgentParameters({
      ...agentParameters,
      [paramLabel]: paramValue
    })
  }

  const handleBuildAgentClick = () => {
    setShowAgentCreation(true)
    // triggerNotification('error', 'Agent building will be available soon')
  }
  const exitAgentCreationPopUp = () => {
    setShowAgentCreation(false)
    setAgentParameters({
      compProfileFreqNum: null,
      compProfileFreqUnit: null,
      quotaCutOff: null,
      quotaGoal: null,
      quotaMetricFieldName: null,
      quotaTimeFrame: null,
      repScope: null,
      salesforceObject: null
    })
  }

  const handleAgentCreate = async () => {
    const agentParametersValid = Object.values(agentParameters).every(value => value !== null && value !== "")

    if (agentParametersValid) {
      setIsCreating(true)
      const repScope = agentParameters['repScope']
      const managerInfo = teamList.find(x => x.type === repScope)
      const managerId = managerInfo.managerId
      const managerEmail = managerInfo.managerEmail
      const agentMap = teamList.reduce((acc, x) => {
        if (repScope === x.type) {
          acc[x.userEmail] = {
            repEmail: x.userEmail,
            repUserId: x.userId,
            roleplayAgentAliasId: null,
            managerId: x.managerId,
            managerEmail: x.managerEmail,
            lastInvestigationTime: 0,
            investigationCount: 0,
            dataAnalysisAgentAliasId: null
          }
        }
        return acc
      }, {})
      const newAgentCluster = {
        ...agentParameters,
        agentId: uuid(),
        company,
        agentName: `${repScope}_${company}_agentCluster`,
        managerId,
        managerEmail,
        repScopeCount: Object.keys(agentMap).length,
        lastCrmAnalysisTime: null,
        lastCompGenTime: null,
        totalInvestigationCount: 0,
        agentMap,
        status: "Creating",
        user_id: userId
      }
      try {
        await GenericAPIRequest("DataIntegration", "put", "/agents/{company}/{repScope}", { company, repScope }, {}, newAgentCluster)
        triggerNotification('success', 'Agent is being generated', `Provisioning agents and generating competency profiles take some time... we'll send you an email when it's done!`)
      } catch (e) {
        console.error("Error creating agent:", e)
        triggerNotification('error', 'There was an error creating the agent')
      }
      agentClusterStore.addAgentCluster(newAgentCluster)
      setAgentClusterList(prevItems => [...prevItems, newAgentCluster]);
    } else {
      triggerNotification('error', 'Please fill out all the information')
    }
    setIsCreating(false)
    setShowAgentCreation(false)
  }
  if (loading) {
    return <OrganizationSectionSkeleton></OrganizationSectionSkeleton>
  }

  return (
    <div className=" max-w-7xl px-4 flex-1">
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
        <div>
          <div class="flex items-center gap-x-3">
            <h1 class="flex gap-x-3 text-lg leading-7">
              <span class="font-semibold text-grey">Agent Clusters</span>
            </h1>
          </div>
          <p class="mt-2 text-xs leading-6 text-gray-400">
            Build AI sales enablement agents for each of your rep types
          </p>
        </div>
        <BuildButton
          buttonLabel="Build agent cluster"
          buttonFunction={handleBuildAgentClick}></BuildButton>
      </div>
      {agentClusterList.length === 0 ?
        <div class="flex relative top-20 flex-1 flex-col items-center justify-center gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <EmptyState
            header="No existing agent clusters"
            subHeader="Build and design agents to work for you"
            buttonLabel="Build Agent"
            buttonFunction={handleBuildAgentClick}
            buttonIcon="build"
            icon="agent"></EmptyState>
        </div>
        : <div class="flex w-2/3 flex-1 flex-col items-start justify-between gap-x-8 gap-y-4 py-4 sm:flex-row sm:items-center rounded">
          <AgentTable agentClusters={agentClusterList}></AgentTable>
        </div>}
      {showAgentCreation ?
        <AgentCreationPopUp
          teamTypes={teamTypes}
          leftButtonLabel="Cancel"
          leftButtonFunction={exitAgentCreationPopUp}
          rightButtonLabel="Create"
          rightButtonFunction={handleAgentCreate}
          handleAgentParametersChange={handleAgentParametersChange}
          loading={isCreating}></AgentCreationPopUp>
        : null}
    </div>
  )
}

export default AgentsSection
