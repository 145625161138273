import React, { useState, useEffect } from 'react'
import RolePlayCards from "../LearningCenter/rolePlayCards";
import AIFormattedText from "../TextComponents/AIFormattedText"
import BackButton from "../Buttons/backButton"

export default function InsightsTab({ salesActivities }) {

  const [inspectRecCoaching, setInspectRecCoaching] = useState(false)
  const [inspectCoachingData, setInspectCoachingData] = useState({})


  return (
    <div className="px-4 py-5 sm:px-0 sm:py-0">
      <dl className="space-y-8 sm:space-y-0">
        {!inspectRecCoaching ? <RolePlayCards
          salesActivities={salesActivities}
          setInspectRecCoaching={setInspectRecCoaching}
          setInspectCoachingData={setInspectCoachingData} />
          :
          <>
            <BackButton
              buttonLabel={'Back'}
              buttonFunction={() => setInspectRecCoaching(false)}></BackButton>
            {/* <h1 class="p-4 font-semibold text-base">Related opportunity: {inspectCoachingData.targetedOppName}</h1> */}
            <div class=" p-4 text-xs text-gray-500 whitespace-pre-line">
              <AIFormattedText content={inspectCoachingData.opportunityProgressionReport} small={true}></AIFormattedText>
            </div>
          </>
        }
      </dl>
    </div>
  )
}
