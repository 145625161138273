import React, { useState } from 'react';

const InfoToolTip = ({ children, content, position = 'bottom' }) => {
  const [visible, setVisible] = useState(false);

  // Define Tailwind classes for each position.
  const positionClasses = {
    top: 'bottom-full left-1/2 transform -translate-x-1/2 mb-2',
    right: 'left-full top-1/6 transform -translate-y-1/2 ml-2',
    bottom: 'top-full left-1/2 transform -translate-x-1/2 mt-2',
    left: 'right-full top-1/2 transform -translate-y-1/2 mr-2',
  };

  return (
    <div
      className="relative  cursor-pointer"
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <div
          className={`absolute z-50 p-4 bg-gray-700 text-xs min-w-[400px] rounded-lg ${positionClasses[position]}`}
        >
          <p class="text-white font-normal whitespace-pre-line">{content}</p>
        </div>
      )}
    </div>
  );
};

export default InfoToolTip;
