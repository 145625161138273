import React, { useEffect, useState } from "react"
import { Auth, API } from "aws-amplify"
import IntegrationCard from "./integrationcard"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Nango from "@nangohq/frontend"
import { useStores } from "../../models/root-store"
import AddButton from "../Buttons/addButton"

const nango = new Nango()

const Integrations = () => {
  const { userStore } = useStores()
  const company = userStore.user.company
  const userId = userStore.user.userId
  const [integrationStatus, setIntegrationStatus] = useState({
    salesforce: { isConnected: false },
    gong: { isConnected: false },
    salesloft: { isConnected: false },
  })
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [pareIntelligenceEnabled, setPareIntelligenceEnabled] = useState(false)

  const availableIntegrations = {
    CRM: [
      {
        integration_id: "salesforce",
        name: "Salesforce",
        description: "Integrate your Salesforce CRM data with Pare",
        detailedDescription:
          "Pare uses your CRM data to analyze opportunities and correlate your sales team information with our AI systems",
        icon: "https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg",
      },
    ],
    CallIntelligence: [
      {
        integration_id: "gong",
        name: "Gong",
        description: "AI powered platform for revenue growth",
        detailedDescription:
          "Pare requires at least one call intelligence provider integration to power our call analysis module",
        icon: "https://seekvectorlogo.com/wp-content/uploads/2022/02/gong-io-vector-logo-2022-small.png",
      },
      {
        integration_id: "salesloft",
        name: "Salesloft",
        description: "Improve your call intelligence with Salesloft.",
        detailedDescription:
          "Pare requires at least one call intelligence provider integration to power our call analysis module",
        icon: "https://findvectorlogo.com/wp-content/uploads/2022/02/salesloft-vector-logo-2022.png",
      },
    ],
  }

  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const { integrations, token } = await API.get("DataIntegration", "/getIntegrations", {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
          },
          queryStringParameters: {
            user_id: userId,
          },
        })

        setToken(token)
        const statusUpdate = { ...integrationStatus }

        integrations.forEach(integration => {
          if (integration.integration_id in statusUpdate) {
            statusUpdate[integration.integration_id].isConnected = true
          }
        })

        setIntegrationStatus(statusUpdate)
        setIsLoading(false)
        checkPareIntelligenceStatus(statusUpdate)
      } catch (error) {
        console.error("Error fetching integrations: ", error)
        setIsLoading(false)
      }
    }

    fetchIntegrations()
  }, [])

  const checkPareIntelligenceStatus = statusUpdate => {
    // Check if a CRM is connected
    const isCRMConnected = statusUpdate.salesforce.isConnected

    // Check if at least one Call Intelligence provider is connected
    const isCallIntelligenceConnected = statusUpdate.gong.isConnected || statusUpdate.salesloft.isConnected

    // Set Pare Intelligence status
    setPareIntelligenceEnabled(isCRMConnected && isCallIntelligenceConnected)
  }

  const handleIntegrationConnect = async () => {
    try {
      const connect = nango.openConnectUI({
        onEvent: event => {
          if (event.type === "close") {
            // Handle modal closed.
          } else if (event.type === "connect") {
            // Update integration status
            setIntegrationStatus(prevStatus => {
              const updatedStatus = { ...prevStatus, [event.payload.providerConfigKey]: { isConnected: true } }
              checkPareIntelligenceStatus(updatedStatus) // Check Pare Intelligence after updating status
              return updatedStatus
            })
          } else if (event.type === "ready") {
            console.log("ready")
          }
        },
      })
      connect.setSessionToken(token)
      console.log(`${company} connected successfully.`)
    } catch (error) {
      console.error("Error connecting integration:", error)
    }
  }

  const handleIntegrationDisconnect = async integration_id => {
    try {
      const connection_id = `${integration_id}-${company}`

      // Call your backend API to delete the connection
      const response = await API.put("DataIntegration", "/deleteNangoConnection", {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
        },
        body: {
          user_id: userId,
          integration_id,
          connection_id,
          company: company,
        },
      })

      console.log(`${company} disconnected successfully from ${integration_id}.`)

      // Update integration status
      setIntegrationStatus(prevStatus => ({
        ...prevStatus,
        [integration_id]: { isConnected: false },
      }))
    } catch (error) {
      console.error("Error disconnecting integration:", error)
    }
  }

  const renderIntegrationCards = integrations => {
    return integrations.map(integration => (
      <IntegrationCard
        key={integration.integration_id}
        name={integration.name}
        icon={integration.icon}
        description={integration.description}
        detailedDescription={integration.detailedDescription}
        isConnected={integrationStatus[integration.integration_id] ?.isConnected}
        onDisconnect={() => handleIntegrationDisconnect(integration.integration_id)}
      />
    ))
  }

  if (isLoading) {
    return (
      <div className="max-w-full mx-auto py-10 px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg font-medium text-gray-900 mb-4">Manage Your Integratons</h2>
        <div className="grid gap-6 sm:grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
          {[...Array(6)].map((_, index) => (
            <div key={index}>
              <Skeleton height={300} />
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="max-w-full mx-auto py-1">
      {/* CRM Integrations */}
      <div className="mb-8">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-md font-semibold text-gray-800">CRM Integrations</h3>
          <AddButton buttonLabel="Connect Integration" buttonFunction={handleIntegrationConnect}></AddButton>
        </div>
        <div className="grid gap-6 sm:grid-cols-[repeat(auto-fit,minmax(250px,300px))]">
          {renderIntegrationCards(availableIntegrations.CRM)}
        </div>
      </div>

      {/* Call Intelligence Integrations */}
      <div>
        <h3 className="text-md font-semibold text-gray-800 mb-4">Call Intelligence Integrations</h3>
        <div className="grid gap-6 sm:grid-cols-[repeat(auto-fit,minmax(250px,300px))]">
          {renderIntegrationCards(availableIntegrations.CallIntelligence)}
        </div>
      </div>

      <div className="mt-8">
        <h3 className="text-lg font-semibold">
          Pare Agents Status:
          {pareIntelligenceEnabled ? (
            <span className="ml-2 text-green-600">Enabled</span>
          ) : (
              <span className="ml-2 text-red-600">Disabled</span>
            )}
        </h3>
        <p className="text-sm text-gray-600">
          {pareIntelligenceEnabled
            ? "Pare Agent Clusters are currently active and analyzing your CRM and call data."
            : "Pare Agent Clusters require at least one CRM and one Call Intelligence provider to be connected."}
        </p>
      </div>
    </div>
  )
}

export default Integrations
