"use client"

import { useState, useEffect, useContext } from "react"
import AIFormattedText from "../TextComponents/AIFormattedText"
import { observer } from "mobx-react-lite"
import Avatar from "../Global/avatar"
import BarChartComparison from "../Homedash/compBarChart"
import DoughnutMetric from "./doughnutMetric"
import { useStores } from "../../models/root-store"
import { GetRepHomepageInfo } from "../../api/routes"
import { NotificationContext } from "../../contexts/notificationContext"
import HomePageSkeleton from "./skeletons"



function RepDashboard({ }) {

  const [loading, setLoading] = useState(true)
  const { userStore } = useStores()
  const { userId, company, userEmail, managerEmail, type, name } = userStore.user
  const [repHomePageMetrics, setRepHomePageMetrics] = useState({})
  const [compChartData, setCompChartData] = useState({})
  const [competencyReport, setCompetencyReport] = useState("")
  const { triggerNotification } = useContext(NotificationContext)

  const fetchData = async () => {
    const repHomeInfoRes = await GetRepHomepageInfo(userId, userEmail, company)
    if (repHomeInfoRes.kind === 'ok') {
      const data = repHomeInfoRes.data
      const overallSim = data.competencyProfile.overallAverageSimilarity ?
        Math.round(data.competencyProfile.overallAverageSimilarity * 10000) / 100 : "N/A"

      setRepHomePageMetrics({
        overallAvgSimilarity: overallSim,
        group: data.analyzedRepSalesforceData.quadrant || "N/A",
        RPsCompleted: data.RPsCompleted,
        quotaAttainmentPercent: Math.round((data.analyzedRepSalesforceData.quotaAttainment / 168000) * 100)
      })
      setCompetencyReport(data.competencyProfile.report || "N/A")
      const competencyProfile = JSON.parse(data.competencyProfile.perCompetencyJson || "{}")
      const barChartLabels = Object.keys(competencyProfile)
      const barChartData = Object.values(competencyProfile)
      setCompChartData({ barChartLabels, barChartData })
    } else {
      triggerNotification("error", 'There was an error loading your data')
      console.log('Error fetching rep homepage data')
    }
  }

  useEffect(() => {
    if (!userStore.user) {
      return
    }

    const initialize = async () => {
      await fetchData()
      setLoading(false)
    }

    initialize()
  }, [])

  if (loading) {
    return <HomePageSkeleton></HomePageSkeleton>
  }


  return (
    <>
      <div class="mt-4 mx-2 flex flex-row gap-x-4 w-full">
        <div className="flex flex-col w-[35vw]">
          <div className="flex flex-row gap-x-4">
            <Avatar name={name} profilePicture={null} size="giant" />
            <div className="ml-4 ">
              <h1 className="font-bold text-gray-900 text-xl">{name}</h1>
              <div className="flex flex-row gap-x-6 mt-4 mb-4">
                <div className="flex flex-col">
                  <label className="font-semibold text-gray-400 text-sm">Role</label>
                  <label className="text-gray-400 text-sm">{type}</label>
                </div>
                <div className="flex flex-col">
                  <label className="font-semibold text-gray-400 text-sm">Manager</label>
                  <label className="text-gray-400 text-sm">{managerEmail}</label>
                </div>

              </div>
              <label className="text-pareBlue font-semibold text-sm mt-8 rounded p-1 bg-blue-100">{repHomePageMetrics.group}</label>

            </div>
          </div>

          <div className="flex flex-row items-center justify-between my-12 min-h-[20vh]">
            <div className="flex flex-col items-center mx-auto">
              <h1 className="font-bold text-pareBlue text-2xl">{repHomePageMetrics.RPsCompleted}</h1>
              <label className="text-gray-400 text-sm">Roleplays Completed</label>
            </div>
            <div className="flex flex-col items-center mx-auto ">
              <h1 className="font-bold text-pareBlue text-2xl">{`${repHomePageMetrics.quotaAttainmentPercent}%`}</h1>
              <label className="text-gray-400 text-sm">Quota Attainment</label>
            </div>
          </div>
          <div className="flex items-center justify-center">

            <DoughnutMetric
              percentage={repHomePageMetrics.overallAvgSimilarity}
              interiorLabel={"Top Rep Similarity"}></DoughnutMetric>
          </div>
        </div>
        <div className="flex flex-col w-[45vw] ">
          <div>
            <div className="text-gray-400 whitespace-pre-line text-sm max-h-[24vh] overflow-scroll mb-8">
              <AIFormattedText content={competencyReport} small={true} color={"light gray"}></AIFormattedText>
            </div>
          </div>
          <BarChartComparison
            barChartData={compChartData.barChartData}
            barChartLabels={compChartData.barChartLabels}
            repName={name}
          ></BarChartComparison>
        </div>
      </div>
    </>
  )
}

export default observer(RepDashboard)
