import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const gongIcon = "https://seekvectorlogo.com/wp-content/uploads/2022/02/gong-io-vector-logo-2022-small.png"
const sfIcon = "https://upload.wikimedia.org/wikipedia/commons/f/f9/Salesforce.com_logo.svg"

export default function SourceList({ sources, opportunityName }) {


  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <MenuButton
            className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            Sources
          <ChevronDownIcon aria-hidden="true" className="-mr-1 size-5 text-gray-400" />
          </MenuButton>
        </div>

        <MenuItems
          transition
          className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            <MenuItem>
              <div class="flex items-center">
                <img src={sfIcon} className="h-9 w-9 pl-2" loading="lazy">
                </img>
                <a
                  onClick={(e) => e.stopPropagation()}
                  className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                >
                  {`${opportunityName}`}
                </a>
              </div>
            </MenuItem>
            {sources.map(sourceId => {
              return (
                <MenuItem>
                  <div class="flex items-center">
                    <img src={gongIcon} className="h-9 w-9 pl-2" loading="lazy">
                    </img>
                    <a
                      onClick={(e) => e.stopPropagation()}
                      className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                    >
                      {`callId: ${sourceId}`}
                    </a>
                  </div>
                </MenuItem>)
            })}
          </div>
        </MenuItems>
      </Menu>
    </div>
  )
}