import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css';

// Breadcrumb Skeleton
export const BreadcrumbSkeleton = () => (
  <div className="mb-8">
    <Skeleton height={40} width={200} />
  </div>
);

// Breadcrumb Skeleton
export const HomePageBodySkeleton = () => (
  <div className="mb-8 h-[80vh]">
    <Skeleton height={"100%"} width={"100%"} />
  </div>
);


export const HomePageSkeleton = () => (
  <div className="w-full h-full p-8">
    {/* Breadcrumb Skeleton */}
    <BreadcrumbSkeleton />

    <HomePageBodySkeleton></HomePageBodySkeleton>

  </div>
);

export default HomePageSkeleton
