import React, { useState, useEffect, useContext } from "react"
import { Auth, API } from "aws-amplify"
import WarningPopUp from "../../components/PopUps/WarningPopUp"
import InviteUserPopUp from "../../components/PopUps/InviteUserPopUp"
import LoadingPopUp from "../../components/PopUps/LoadingPopUp"
import TeamTable from "../../components/TeamPage/teamTable"
import GroupedTeamTable from "../../components/TeamPage/groupedTable"
import "react-multi-email/dist/style.css"
import { observer } from "mobx-react-lite"
import { useStores } from "../../models/root-store"
import { toJS } from "mobx"
import TeamPageSkeleton from "../../components/TeamPage/skeletons"
import { NotificationContext } from "../../contexts/notificationContext"
import AddButton from "../../components/Buttons/addButton"

const MyTeamPage = () => {
  const { userStore, teamStore, organizationStore } = useStores()
  const teamMemberList = toJS(teamStore.teamInfo ?.team) || []
  const inviteeList = toJS(teamStore.teamInfo ?.inviteeList) || []
  const repTypeOptions = toJS(organizationStore.organizationConfig ?.repTypes) || []
  const [showInviteUserPopUp, setShowInviteUserPopUp] = useState(false)
  const [showDeletePopUp, setShowDeletePopUp] = useState(false)
  const [deleteDetails, setDeleteDetails] = useState({})
  const [popUpLoading, setPopUpLoading] = useState(false)
  const [emails, setEmails] = useState([])
  const [focused, setFocused] = useState(false)
  const [userType, setUserType] = useState("")
  const [pageLoading, setPageLoading] = useState(true)
  const { triggerNotification } = useContext(NotificationContext)


  useEffect(() => {

    const fetchTeam = async () => {
      if (!teamStore.teamInfo) {
        await teamStore.getTeam(userStore.user.userId, userStore.user.type, userStore.user.company, userStore.user.userId)
      }
    }
    const fetchOrgSettings = async () => {
      if (!organizationStore.organizationConfig) {
        await organizationStore.getOrgSettings(userStore.user.company, userStore.user.userId)
      }
    }
    const fetchData = async () => {
      try {
        await Promise.all([fetchTeam(), fetchOrgSettings()])
        // call assignemnts api, check if curr signed in user has assignment and status != in progress || pending
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        // Ensure page loading is set to false after both fetches or if already populated
        setPageLoading(false)
      }
    }

    fetchData()

  }, [])

  const deleteInvitee = async inviteeEmail => {
    await API.post("ZenoApp", "/deleteInvitee", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        inviteeEmail,
        user_id: userStore.user.userId,
      },
    })
    teamStore.removeInvitedRep(inviteeEmail)
    triggerNotification("Invite Removed", `Invite for ${inviteeEmail} has been successfully removed.`)
  }
  const openDeletePopUp = teamMemberEntryObj => {
    setDeleteDetails(teamMemberEntryObj)
    setShowDeletePopUp(true)
  }
  const closeDeletePopUp = () => {
    setShowDeletePopUp(false)
    setDeleteDetails(null)
  }

  const deleteUser = async () => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/deleteUser", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        deleted_user_id: deleteDetails.user_id,
        user_id: userStore.user.userId,
      },
    })
    teamStore.removeSalesRep(deleteDetails.user_id)
    setShowDeletePopUp(false)
    setDeleteDetails(null)
    setPopUpLoading(false)
    triggerNotification("User Removed", `${deleteDetails.name} has been successfully removed.`)
  }

  const updateManagerInfo = async (userEmail, managerId, managerEmail, managerName, isActiveUser) => {
    setPopUpLoading(true)
    await API.post("ZenoApp", "/updateUserManagerInfo", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        userEmail: userEmail,
        managerId: managerId,
        managerEmail: managerEmail,
        managerName: managerName,
        isActiveUser: isActiveUser,
        user_id: userStore.user.userId,
      },
    })
    if (isActiveUser) {
      teamStore.updateSalesRepManagerInfo(userEmail, managerId, managerEmail, managerName)
    } else {
      teamStore.updateInvitedRepManagerInfo(userEmail, managerId, managerName)
    }
    setPopUpLoading(false)
    triggerNotification("Manager Updated", `${userEmail}'s manager has been successfully updated to ${managerName}.`)
  }

  const sendInviteToUsers = async () => {
    if (userType.length > 0 && emails.length > 0) {
      setPopUpLoading(true)
      await API.post("ZenoApp", "/sendInvites", {
        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
        body: {
          managerName: userStore.user.name,
          managerId: userStore.user.userId,
          emailList: emails.join(","),
          company: userStore.user.company,
          type: userType,
          inviteSenderType: userStore.user.type,
          user_id: userStore.user.userId,
        },
      })
      teamStore.addInvitedRep(
        emails,
        userType,
        userStore.user.type == "Admin" ? "UNDEFINED" : userStore.user.userId,
        userStore.user.name,
      )
      setEmails([])
      setUserType("")
      setPopUpLoading(false)
      setShowInviteUserPopUp(false)
      triggerNotification("Invites Sent", `Invites have been successfully sent to ${emails.join(", ")}.`)
    } else {
      triggerNotification("error", "Error", `Please fill out all the information`)
    }
  }

  const resendInvite = async inviteeEmail => {
    await API.post("ZenoApp", "/renewInvite", {
      headers: { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` },
      body: {
        userEmail: inviteeEmail,
        user_id: userStore.user.userId,
      },
    })
    triggerNotification("Invite Resent", `Invite successfully resent to ${inviteeEmail}.`)
  }

  const handleInviteUserClick = () => setShowInviteUserPopUp(true)
  const handleTypeChange = event => setUserType(event.target.value)
  const handleBackButtonClick = () => setShowInviteUserPopUp(false)

  const inviteUserPopUp = showInviteUserPopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Inviting Reps..."></LoadingPopUp>
      ) : (
          <InviteUserPopUp
            header={"Add Team Member"}
            body={`Please enter the emails of your new team members, click enter when you've written out the whole email`}
            leftButtonLabel="Cancel"
            leftButtonFunction={handleBackButtonClick}
            rightButtonLabel="Send Invite"
            rightButtonFunction={sendInviteToUsers}
            setEmails={setEmails}
            setFocused={setFocused}
            handleTypeChange={handleTypeChange}
            userType={userStore.user.type}
            repTypeOptions={repTypeOptions}></InviteUserPopUp>
        )}
    </div>
  ) : null

  const deleteUserPopUp = showDeletePopUp ? (
    <div className={`frostedBackground is-visible`}>
      {popUpLoading ? (
        <LoadingPopUp header="Deleting Reps..."></LoadingPopUp>
      ) : (
          <WarningPopUp
            header="Remove Team Member"
            body={`Are you sure you would like to remove ${deleteDetails.name}?`}
            leftButtonFunction={closeDeletePopUp}
            leftButtonLabel="Cancel"
            rightButtonFunction={deleteUser}
            rightButtonLabel="Confirm Removal"></WarningPopUp>
        )}
    </div>
  ) : null

  if (pageLoading) {
    return <TeamPageSkeleton />
  }

  return (
    <>
      <div className="w-full overflow-hidden">
        <div className=" w-full">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="font-semibold text-lg text-grey">User management</h1>
              <p className="mt-2 text-xs leading-6 text-gray-400">Provision, configure, and manage users and teams</p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <AddButton buttonLabel="Add user" buttonFunction={handleInviteUserClick}></AddButton>
            </div>
          </div>

          {userStore.user.type === "Admin" ? (
            <GroupedTeamTable
              teamMemberList={teamMemberList}
              inviteeList={inviteeList}
              pageLoading={pageLoading}
              openDeletePopUp={openDeletePopUp}
              deleteInvitee={deleteInvitee}
              updateManagerInfo={updateManagerInfo}
              resendInvite={resendInvite}
              currentUserName={userStore.user.name}
            />
          ) : (
              <TeamTable
                teamMemberList={teamMemberList}
                inviteeList={inviteeList}
                pageLoading={pageLoading}
                openDeletePopUp={openDeletePopUp}
                deleteInvitee={deleteInvitee}
                resendInvite={resendInvite}
                currentUserName={userStore.user.name}
              />
            )}
        </div>
      </div>
      {inviteUserPopUp}
      {deleteUserPopUp}
    </>
  )
}
export default observer(MyTeamPage)
