import React from "react"

const Avatar = ({ name, profilePicture, size = "md" }) => {
  const sizes = {
    sm: "h-6 w-6 text-xs",
    md: "h-8 w-8 text-sm",
    lg: "h-10 w-10",
    xl: "h-12 w-12 text-lg",
    xxl: "h-14 w-14 text-xl",
    giant: "h-24 w-24 text-xl",
  }

  const containerSize = sizes[size] || sizes.md

  // Derive initials from the name if provided
  const initials = name
    ? name
      .split(" ")
      .map(n => n[0])
      .join("")
      .toUpperCase()
    : null

  return (
    <span className={`inline-flex items-center justify-center rounded-full ${containerSize}`}>
      {profilePicture ? (
        <img alt="User Profile" src={profilePicture} className={`rounded-full ${containerSize}`} />
      ) : initials ? (
        <span className={`inline-flex rounded-full items-center justify-center bg-gray-500 ${containerSize}`}>
          <span className="font-medium text-white">{initials}</span>
        </span>
      ) : (
            <span className="overflow-hidden rounded-full bg-gray-100">
              <svg fill="currentColor" viewBox="0 0 24 24" className="h-full w-full text-gray-300">
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
    </span>
  )
}

export default Avatar
