import { types, Instance, SnapshotOut } from "mobx-state-tree"
import { UserStoreModel, UserStore } from "../user-store/user-store"
import { TeamStoreModel, TeamStore } from "../team-store/team-store"
import { ModuleListStoreModel, ModuleListStore } from "../module-store/module-store"
import { ModuleListStoreModelV2, ModuleListStoreV2 } from "../module-store/module-store-v2"
import { AssignmentStoreModel, AssignmentStore } from "../assignment-store/assignment-store"
import { AttemptListStoreModel, AttemptListStore } from "../attempt-store/attempt-store"
import { LearningPathListStoreModel, LearningPathStore } from "../lp-store/lp-store"
import { UsageStoreModel, UsageStore } from "../admin-console-stores/usage-store"
import { OrganizationStoreModel, OrganizationStore } from "../admin-console-stores/organization-store"
import { PersonaListStoreModel, PersonaListStore } from "../persona-store/persona-store"
import { AgentClusterStoreModel, AgentClusterStore } from "../admin-console-stores/agent-store"
import { RecModuleStoreModel, RecModuleStore } from "../rec-module-store/rec-module-store"


export const RootStoreModel = types.model("RootStore").props({
  userStore: types.optional(UserStoreModel, {} as any),
  teamStore: types.optional(TeamStoreModel, {} as any),
  moduleListStore: types.optional(ModuleListStoreModel, {} as any),
  moduleListStoreV2: types.optional(ModuleListStoreModelV2, {} as any),
  assignmentStore: types.optional(AssignmentStoreModel, {} as any),
  attemptListStore: types.optional(AttemptListStoreModel, {} as any),
  learningPathStore: types.optional(LearningPathListStoreModel, {} as any),
  usageStore: types.optional(UsageStoreModel, {} as any),
  organizationStore: types.optional(OrganizationStoreModel, {} as any),
  personaStore: types.optional(PersonaListStoreModel, {} as any),
  agentClusterStore: types.optional(AgentClusterStoreModel, {} as any),
  recModuleStore: types.optional(RecModuleStoreModel, {} as any),
})
export interface RootStore extends Instance<typeof RootStoreModel> { }
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> { }

// This is needed to break RootStore's circular dependencies
export type RootStoreType = {
  userStore: UserStore
  teamStore: TeamStore
  moduleListStore: ModuleListStore
  moduleListStoreV2: ModuleListStoreV2
  assignmentStore: AssignmentStore
  attemptListStore: AttemptListStore
  learningPathStore: LearningPathStore
  usageStore: UsageStore
  organizationStore: OrganizationStore
  personaStore: PersonaListStore
  agentClusterStore: AgentClusterStore
  recModuleStore: RecModuleStore
}
