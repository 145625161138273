import { useState } from "react"
import PersonalInformation from "../../components/Settings/personalInformation"
import { useStores } from "../../models/root-store"

// Utility for conditional class names
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function CombinedModularSettings() {
  const { userStore } = useStores()
  const userType = userStore.user.type

  // Single condition: are we an Admin or Manager?
  const isAdminOrManager = userType === "Admin" || userType === "Manager"

  // Base tabs that everyone can see:
  const baseTabs = [{ name: "Personal Information", key: "personalInformation" }]

  // "restricted" tabs for admins/managers only — currently empty,
  // e.g. { name: 'Analytics', key: 'analytics' },
  const adminManagerTabs = []

  // Combine them if the user is Admin/Manager, otherwise just use baseTabs
  const allTabs = isAdminOrManager ? [...baseTabs, ...adminManagerTabs] : baseTabs

  // Initialize the current tab to the first available, if any
  const [currentTab, setCurrentTab] = useState(allTabs[0]?.key ?? "")

  return (
    <div className="px-6 w-full">
      <h1 className="sr-only">Account Settings</h1>

      <header className="border-b border-gray-200 pb-5 sm:pb-0 mt-10">
        <h3 className="text-base font-semibold leading-6 text-gray-900">Settings</h3>
        <div className="mt-3 sm:mt-4">
          {/* Mobile dropdown */}
          <div className="sm:hidden">
            <label htmlFor="current-tab" className="sr-only">
              Select a tab
            </label>
            <select
              id="current-tab"
              name="current-tab"
              value={allTabs.find(tab => tab.key === currentTab)?.name || ""}
              onChange={e => setCurrentTab(allTabs.find(tab => tab.name === e.target.value)?.key ?? "")}
              className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base 
                         focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
              {allTabs.map(tab => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>

          {/* Desktop tabs */}
          <div className="hidden sm:block">
            <nav className="-mb-px flex space-x-8">
              {allTabs.map(item => (
                <button
                  key={item.name}
                  onClick={() => setCurrentTab(item.key)}
                  aria-current={currentTab === item.key ? "page" : undefined}
                  className={classNames(
                    currentTab === item.key
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium",
                  )}>
                  {item.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </header>

      {/* Settings content */}
      <div className="divide-y divide-gray-200 py-5 max-w-7xl mx-auto grid grid-cols-1 gap-x-8 gap-y-10 px-4 sm:px-6 md:grid-cols-3 lg:px-8">
        <div>
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            {currentTab === "personalInformation" ? "Personal Information" : "No Tab Selected"}
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-400">
            {currentTab === "personalInformation" ? "View your personal account information" : "No tab selected"}
          </p>
        </div>

        <div className="md:col-span-2">
          {currentTab === "personalInformation" && <PersonalInformation />}
          {/* Additional future tabs for admin/manager go here if you add them */}
        </div>
      </div>
    </div>
  )
}
