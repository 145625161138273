import { types, Instance, flow, SnapshotIn } from "mobx-state-tree"
import { getAgentClusters } from "../../api/routes"

const AgentFieldsModel = types
  .model("AgentSectionsFields", {
    investigationCount: types.number,
    lastInvestigationTime: types.number,
    repEmail: types.string,
    repUserId: types.string,
    userName: types.string,
    userEmail: types.maybeNull(types.string),
    dataAnalysisAgentAliasId: types.maybeNull(types.string),
    roleplayAgentAliasId: types.maybeNull(types.string),
  })


const AgentClusterFieldsModel = types
  .model("AgentClusterSectionsFields", {
    agentClusterId: types.string,
    company: types.string,
    agentName: types.string,
    quotaGoal: types.number,
    quotaMetric: types.maybeNull(types.string),
    quotaMetricFieldName: types.string,
    salesforceObject: types.string,
    managerId: types.string,
    managerEmail: types.string,
    managerName: types.string,
    repScope: types.string,
    quotaTimeFrame: types.string,
    lastCrmAnalysisTime: types.maybeNull(types.number),
    lastCompGenTime: types.maybeNull(types.string),
    compProfileFreqUnit: types.string,
    compProfileFreqNum: types.number,
    quotaCutOff: types.number,
    totalInvestigationCount: types.number,
    dataAnalysisAgentId: types.maybeNull(types.string),
    roleplayCreationAgentId: types.maybeNull(types.string),
    agentMap: types.maybeNull(types.map(AgentFieldsModel)),
    status: types.string,

  })


const AgentClusterListFieldsModel = types
  .model("AgentClusterListFields", {
    agentClusterList: types.array(AgentClusterFieldsModel)
  })

export type AgentClusterListSnapshotIn = SnapshotIn<typeof AgentClusterListFieldsModel>


export const AgentClusterStoreModel = types
  .model("AgentClusterStore", {
    agents: types.maybeNull(AgentClusterListFieldsModel),
  })
  .actions(self => ({
    getAllAgentClusters: flow(function* getAllAgentClusters(user_id: string) {

      try {
        const agentRes = yield getAgentClusters(user_id)
        console.log(agentRes.data)
        if (agentRes.kind === "ok") {
          self.agents = AgentClusterListFieldsModel.create({ agentClusterList: agentRes.data })
          return true
        }
      } catch (e) {
        return false
      }
      return false
    }),

    addAgentCluster(agentClusterObj) {
      if (!self.agents) {
        self.agents = AgentClusterListFieldsModel.create({ agentClusterList: [agentClusterObj] })
        return true
      }
      self.agents.agentClusterList.push(AgentClusterFieldsModel.create(agentClusterObj))
      return true
    }
  }))

export type AgentClusterStore = Instance<typeof AgentClusterStoreModel>