import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { SparklesIcon } from '@heroicons/react/16/solid';

const AgentTooltip = ({ children, header, content, position = 'right' }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipStyles, setTooltipStyles] = useState({});
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (visible && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      let style = {};

      switch (position) {
        case 'right':
          style = {
            top: rect.top + rect.height / 2,
            left: rect.right + 8,
            transform: 'translateY(-50%)',
          };
          break;
        case 'topRight':
          style = {
            top: rect.top - 450,
            left: rect.right + 15,
          };
          break;
        case 'top':
          style = {
            top: rect.top - 8,
            left: rect.left + rect.width / 2,
            transform: 'translateX(-50%)',
          };
          break;
        case 'bottom':
          style = {
            top: rect.bottom + 8,
            left: rect.left + rect.width / 2,
            transform: 'translateX(-50%)',
          };
          break;
        case 'left':
          style = {
            top: rect.top + rect.height / 2,
            left: rect.left - 8,
            transform: 'translateY(-50%)',
          };
          break;
        default:
          style = {
            top: rect.top + rect.height / 2,
            left: rect.right + 8,
            transform: 'translateY(-50%)',
          };
      }

      setTooltipStyles(style);
    }
  }, [visible, position]);

  // Hide tooltip when clicking outside of it.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target)
      ) {
        setVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Hide tooltip on scroll. Use capture phase so nested scrolling containers are caught.
  useEffect(() => {
    const handleScroll = () => {
      setVisible(false);
    };

    window.addEventListener('scroll', handleScroll, true);
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  // Render the overlay and tooltip together in a portal.
  const tooltipPortal = visible && createPortal(
    <>
      {/* Full-screen invisible overlay */}
      <div
        onClick={() => setVisible(false)}
        className="fixed inset-0 z-40"
      />
      {/* Tooltip */}
      <div
        ref={tooltipRef}
        style={tooltipStyles}
        className="absolute z-50 p-4 bg-blue-100 text-xs max-w-[400px] rounded-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center mb-4">
          <SparklesIcon
            aria-hidden="true"
            className="mr-4 size-5 text-pareBlue"
          />
          <h1 className="text-pareBlue font-semibold">{header}</h1>
        </div>
        <p className="text-pareBlue font-normal whitespace-pre-line">
          {content}
        </p>
      </div>
    </>,
    document.body
  );

  return (
    <div
      ref={containerRef}
      className="relative inline-block"
      onClick={(e) => {
        e.stopPropagation();
        setVisible(true);
      }}
    >
      {children}
      {tooltipPortal}
    </div>
  );
};

export default AgentTooltip;
