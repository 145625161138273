"use client"

import { useState } from "react"
import { SparklesIcon, MegaphoneIcon, ClipboardIcon, XMarkIcon } from "@heroicons/react/16/solid"
import EmptyState from "../Global/emptyState"
import { observer } from "mobx-react-lite"
import AgentTooltip from "./agentTooltip"
import WarningPopUp from "../PopUps/WarningPopUp"
import DropdownSelector from "../LearningCenter/dropdown"
import SourceList from "./sourceList"
import Avatar from "../Global/avatar"


function TaskDashboard({ inspectTask, recommendations, deleteRecommendation, assignRecommendation, company }) {
  const [showRecCoachingDeletePopUp, setShowRecCoachingDeletePopUp] = useState(false)
  const [deletionInfo, setDeletionInfo] = useState(null)
  const [loading, setLoading] = useState(false)
  const [assigningRec, setAssigningRec] = useState(null)

  const [selectedRep, setSelectedRep] = useState("All")
  const [recommendationsToShow, setRecommendationsToShow] = useState(recommendations)
  const repOptions = recommendations.reduce(
    (acc, repObj) => {
      if (!acc.some(item => item.value === repObj.userEmail)) {
        acc.push({ value: repObj.userEmail, label: repObj.userName })
      }
      return acc
    },
    [{ value: "All", label: "All" }],
  )

  const initiateDeletion = (pk, sk, displayName) => {
    setDeletionInfo({ pk, sk, displayName })
    setShowRecCoachingDeletePopUp(true)
  }

  const confirmedDeletion = async () => {
    setLoading(true)
    await deleteRecommendation(deletionInfo.pk, deletionInfo.sk)
    setShowRecCoachingDeletePopUp(false)
    setLoading(false)
  }

  const onRepSelect = e => {
    const selectedRep = e.target.value
    setSelectedRep(selectedRep)
    if (selectedRep === "All") {
      setRecommendationsToShow(recommendations)
    } else {
      setRecommendationsToShow(recommendations.filter(x => x.userEmail === selectedRep))
    }
  }

  const handleAssignClick = async (e, rec) => {
    e.stopPropagation()
    setAssigningRec(rec.SK)
    await assignRecommendation(rec)
    setAssigningRec(null)
  }

  return (
    <>
      <div class="mx-auto max-w-2xl">
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none ">
          <div className="flex flex flex-col">
            <div class="flex flex-row items-center">
              <ClipboardIcon aria-hidden="true" className="mr-4 size-4 text-gray-900" />
              <h2 className="text-base/7 font-semibold text-gray-900">Current Tasks</h2>
            </div>
            <ul role="list" className="flex flex-row flex-wrap gap-x-4 gap-y-4 items-center justify-center">
              <li class="h-[20vh] flex items-center justify-center">
                <label class="text-gray-400 justify-center">No current tasks at the moment...</label>
              </li>
            </ul>
          </div>

          <div className="flex items-center justify-between mb-4 ">
            <div class="flex flex-row items-center">
              <MegaphoneIcon aria-hidden="true" className="mr-4 size-4 text-gray-900" />
              <h2 className="text-base/7 font-semibold text-gray-900">Recommended Coaching</h2>
            </div>
            {recommendations.length < 1 || company !== "Tines" ? null : (
              <div className="sm:flex sm:items-center">
                <DropdownSelector
                  label="Select rep"
                  options={repOptions}
                  value={selectedRep || ""}
                  onChange={onRepSelect}
                />
              </div>
            )}
          </div>
          {recommendations.length < 1 || company !== "Tines" ? (
            <div class="mt-12">
              <EmptyState
                header="No recs pending review"
                subHeader="Set up Pare Agents and wait for the agents to recommend roleplays"
                removeButton={true}
                icon="artifact"></EmptyState>
            </div>
          ) : (
              <ul role="list" className="flex flex-col gap-x-4 gap-y-4 max-h-[calc(48vh)] overflow-auto">
                {recommendationsToShow.map(rec => (
                  <li
                    key={rec.sk}
                    onClick={() => inspectTask(rec)}
                    className="rounded-xl border border-gray-200  cursor-pointer">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <Avatar
                        name={rec.userName}
                        profilePicture={null} // TODO: handle this later
                        className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />

                      <div className="text-sm/6 font-medium text-gray-900 ">{rec.displayName}</div>

                      <div className="ml-auto flex space-x-2 text-gray-300 ">
                        <button
                          type="button"
                          className="inline-flex items-center gap-x-2 rounded-md bg-white px-3.5 py-1.5 text-sm font-semibold shadow-sm hover:text-red-400 hover:border-red-400 border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={e => {
                            e.stopPropagation()
                            initiateDeletion(rec.PK, rec.SK, rec.displayName)
                          }}>
                          {"Dismiss"}
                          <XMarkIcon aria-hidden="true" className="size-4 " />
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center gap-x-2 rounded-md bg-pareBlue px-3.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={e => (assigningRec === rec.SK ? null : handleAssignClick(e, rec))}>
                          {assigningRec === rec.SK ? "Assigning..." : "Assign"}
                        </button>
                      </div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
                      <div className="flex gap-x-4 py-3">
                        <dt className="text-gray-900 font-semibold">Description:</dt>
                        <dd className="text-gray-600">
                          <p>{rec.description}</p>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3 items-center">
                        <div class="flex cursor-pointer font-semibold">
                          <AgentTooltip
                            header={`Why is this recommended for ${rec.userName.split(" ")[0]}?`}
                            content={rec.recommendationWhy}
                            position="topRight">
                            <div class="flex cursor-pointer">
                              <SparklesIcon
                                aria-hidden="true"
                                className="mr-4 size-6 text-pareBlue hover:text-indigo-600"
                              />
                              <dt className="text-pareBlue hover:text-indigo-600 ">{`Why is this recommended for ${
                                rec.userName.split(" ")[0]
                                }?`}</dt>
                            </div>
                          </AgentTooltip>
                        </div>
                        <SourceList sources={rec.sources} opportunityName={rec.targetedOppName}></SourceList>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            )}
        </div>
      </div>
      {showRecCoachingDeletePopUp ? (
        <WarningPopUp
          header={`Delete "${deletionInfo.displayName}" ?`}
          body={`Are you sure you want to delete this recommended module?`}
          leftButtonLabel="Cancel"
          leftButtonFunction={() => setShowRecCoachingDeletePopUp(false)}
          rightButtonFunction={loading ? null : confirmedDeletion}
          rightButtonLabel={loading ? "Deleting..." : "Delete"}></WarningPopUp>
      ) : null}
    </>
  )
}

export default observer(TaskDashboard)
