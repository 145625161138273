import React from 'react';

const AIFormattedText = ({ content, small = false, color = 'gray' }) => {
  // Dynamic color class based on the passed prop.
  const textColor = color === 'light gray' ? `text-${color}-500` : `text-${color}-800`;

  // Adjust font sizes and padding based on the "small" prop.
  const headingThreeSize = small ? 'text-sm' : 'text-base';
  const headingTwoSize = small ? 'text-base' : 'text-lg';
  const headingOneSize = small ? 'text-base' : 'text-lg';
  const defaultSize = small ? 'text-xs' : 'text-sm';
  const paddingY = small ? 'py-2' : 'py-4';

  // Split the content by lines and map over each line to apply the correct styling.
  const lines = content.split(/\r?\n/).map((line, index) => {
    // Trim the line to remove extra spaces.
    const trimmedLine = line.trim();

    if (trimmedLine.startsWith('###')) {
      return (
        <React.Fragment key={index}>
          <br />
          <span className={`${textColor} font-semibold ${headingThreeSize} ${paddingY}`}>
            {trimmedLine.replace(/^###\s*/, "")}
          </span>
        </React.Fragment>
      );
    } else if (trimmedLine.startsWith('##')) {
      return (
        <React.Fragment key={index}>
          <br />
          <span className={`${textColor} font-semibold ${headingTwoSize} ${paddingY}`}>
            {trimmedLine.replace(/^##\s*/, "")}
          </span>
        </React.Fragment>
      );
    } else if (trimmedLine.startsWith('#')) {
      return (
        <React.Fragment key={index}>
          <br />
          <span className={`${textColor} font-bold ${headingOneSize} ${paddingY}`}>
            {trimmedLine.replace(/^#\s*/, "")}
          </span>
        </React.Fragment>
      );
    } else if (trimmedLine.startsWith('*')) {
      return (
        <span className={`${textColor} ${defaultSize} ${paddingY}`} key={index}>
          {trimmedLine.replace(/\*/g, "")}
        </span>
      );
    } else if (trimmedLine.includes('**')) {
      return (
        <span className={`${textColor} ${defaultSize} font-semibold ${paddingY}`} key={index}>
          {trimmedLine.replace(/\*/g, "")}
        </span>
      );
    }

    return (
      <span className={`${textColor} ${defaultSize} ${paddingY}`} key={index}>
        {trimmedLine}
      </span>
    );
  });

  return (
    <div className="whitespace-pre-line">
      {lines.map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
};

export default AIFormattedText;
