import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutMetric = ({ percentage, interiorLabel }) => {
  // Determine color based on percentage
  let color;
  if (percentage >= 70) {
    color = "#008000";
  } else if (percentage >= 50) {
    color = '#F4B400'; // Yellow
  } else {
    color = '#DB4437'; // Red
  }

  // Data for the Doughnut chart
  const data = {
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [color, '#eaeaea'],
        borderWidth: 0
      }
    ]
  };

  // Chart options
  const options = {
    cutout: '70%', // how 'thick' the doughnut ring is
    plugins: {
      tooltip: {
        enabled: false // hide default tooltip
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div style={{ position: 'relative', width: '200px', height: '200px' }}>
      <Doughnut data={data} options={options} />
      {/* Centered percentage text */}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          pointerEvents: 'none' // so text doesn't block hover on chart
        }}
      >
        <p style={{ margin: 0, fontSize: '2rem' }}>
          {percentage}%
        </p>
        <label className="text-gray-400 text-sm">{interiorLabel}</label>
      </div>
    </div>
  );
};

export default DoughnutMetric;
