import { types, flow, SnapshotIn, Instance } from "mobx-state-tree"
import {
  GetRecommendations,
  DeleteRecommendation,
  AssignRecommendation
} from "../../api/routes" // <-- Adjust to your real routes

const PersonaModel = types.model("Persona", {
  summary: types.maybeNull(types.string),
  displayName: types.maybeNull(types.string),
})

const RubricModel = types.model("Rubric", {
  id: types.string,
  fileName: types.string,
  content: types.string,
})

const ContextModel = types.model("Context", {
  id: types.string,
  fileName: types.string,
  summary: types.string,
})

export const RecommendationModel = types.model("Recommendation", {
  PK: types.string,
  SK: types.string,
  callType: types.string,
  challengeCriteria: types.maybeNull(types.number),
  companyId: types.string,
  context: types.maybeNull(ContextModel),
  createdById: types.string,
  createdByName: types.string,
  creationDate: types.number,
  description: types.maybeNull(types.string),
  displayName: types.string,
  instructions: types.string,
  isAutoGenerated: types.boolean,
  isCreationDone: types.boolean,
  isScreenshareEnabled: types.boolean,
  links: types.maybeNull(types.array(types.string)),
  recommendationWhy: types.string,
  opportunityProgressionReport: types.string,
  managerId: types.string,
  moduleType: types.string,
  objective: types.string,
  persona: types.maybeNull(PersonaModel),
  rubric: types.maybeNull(RubricModel),
  salesActivityId: types.string,
  lastCallId: types.string,
  userName: types.string,
  skillLevel: types.maybeNull(types.string),
  targetedOppName: types.string,
  targetedOppStage: types.string,
  timeLimit: types.number,
  voice: types.string,
  userEmail: types.string,
  sources: types.maybeNull(types.array(types.string))
})

const RecommendationListModel = types.model("RecommendationListModel", {
  recommendationList: types.array(RecommendationModel),
})

export type RecommendationListSnapshotIn = SnapshotIn<typeof RecommendationListModel>

export const RecModuleStoreModel = types
  .model("RecModuleStore", {
    // All user assignments
    recommendations: types.maybeNull(RecommendationListModel)
  })
  .actions(self => ({
    /* --------------------------------------
       1) Get recomendations
    -------------------------------------- */
    getRecs: flow(function* getRecs(managerId: string, userId: string, userType: string) {

      try {
        const res = yield GetRecommendations(managerId, userId, userType)
        console.log(res)
        if (res.kind === "ok") {
          const newRecs = res.data.map((rec: any) => RecommendationModel.create(rec))
          if (!self.recommendations) {
            self.recommendations = RecommendationListModel.create({
              recommendationList: newRecs,
            })
          }
        }
      } catch (e) {
        console.error("[getAssignments] Error:", e)
      }
      return false
    }),
    removeRec: flow(function* removeRec(pk, sk, userId) {
      if (!self.recommendations) return null

      const deletionRes = yield DeleteRecommendation(pk, sk, userId)
      if (deletionRes.kind === 'ok') {
        const index = self.recommendations.recommendationList.findIndex(task => task.SK === sk);
        if (index !== -1) {
          self.recommendations.recommendationList.splice(index, 1);
        }

      } else {
        return false
      }

      return true
    }),
    assignRec: flow(function* assignRec(recommendation, userId) {
      if (!self.recommendations) return null

      const assignRes = yield AssignRecommendation(recommendation, userId)
      if (assignRes.kind === 'ok') {
        console.log('OK')
        const index = self.recommendations.recommendationList.findIndex(rec => rec.SK === recommendation.SK);

        if (index !== -1) {
          self.recommendations.recommendationList.splice(index, 1);
        }

      } else {
        return false
      }

      return true
    })
  }))
  .views(self => ({
    getRecList() {
      if (!self.recommendations) return []
      return self.recommendations.recommendationList
    },
  }))

// MST instance type
export type RecModuleStore = Instance<typeof RecModuleStoreModel>