'use client'

import React, { useState } from 'react'
import { Dialog, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import Avatar from "../Global/avatar"
import Tabs from "../Global/tabs"
import { useStores } from "../../models/root-store";
import InsightsTab from "../TeamAnalysisPage/insightsTab"
import OverviewTab from "./overviewTab"
import TaskPopUp from "../PopUps/TaskPopUp"


export default function AnalysisDrawer({ open, setOpen, analysisDrawerData }) {
  const { userStore } = useStores();
  const userEmail = userStore.user.userEmail;
  const [currentTab, setCurrentTab] = useState('Overview')
  const [inspectData, setInspectData] = useState(null)
  const [showTaskInspect, setShowTaskInspect] = useState(false)
  const salesActivities = analysisDrawerData.salesActivities


  const openTaskPopUp = (rec) => {
    setInspectData(rec)
    setShowTaskInspect(true)
  }

  const closeTaskPopUp = () => {
    setInspectData(null)
    setShowTaskInspect(false)
  }

  const tabNames = [
    { name: "Overview", key: "Overview" },
    { name: "Recommended Coaching", key: "Recommended Coaching" },
  ]



  return (
    <>
      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-2xl transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  {/* Main */}
                  <div className="divide-y divide-gray-200">
                    <div className="pb-3">
                      {/* <div className="h-24 bg-indigo-700 sm:h-20 lg:h-28" /> */}
                      <div className="flow-root pt-6 px-4 sm:-mt-8 sm:flex items-start sm:px-6">
                        <div>
                          <div className="mt-6 flex ">
                            <div className="items-start overflow-hidden rounded-lg border-4 border-white">
                              <Avatar name={analysisDrawerData.name} profilePicture={null} size="lg" />
                            </div>
                          </div>
                        </div>
                        <div className="mt-6 sm:ml-6 sm:flex-1">
                          <div>
                            <div className="flex items-center justify-between">
                              <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{analysisDrawerData.name}</h3>
                              <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                              >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon aria-hidden="true" className="size-6" />
                              </button>
                            </div>
                            <label className="rounded text-sm text-pareBlue p-1 bg-blue-100">{analysisDrawerData.group}</label>

                          </div>


                        </div>
                      </div>
                      <div className=" px-4 mt-5 flex flex-wrap">
                        <Tabs tabNames={tabNames} selectedTab={currentTab} setSelectedTab={setCurrentTab} ></Tabs>
                      </div>
                    </div>

                    <div className="px-4 py-3 sm:px-0 sm:py-0">
                      <dl className="space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                        {currentTab === 'Overview' ?
                          <OverviewTab
                            barChartLabels={Object.keys(analysisDrawerData ?.competencies || [])}
                            barChartData={Object.values(analysisDrawerData ?.competencies || [])}
                            overallAverageSimilarity={analysisDrawerData ?.overallAverageSimilarity || 0}
                            distributionDistance={analysisDrawerData ?.distributionDistance || 0}
                            repName={analysisDrawerData ?.name || ""}
                            report={analysisDrawerData.report}
                          ></OverviewTab>
                          : <InsightsTab salesActivities={salesActivities} openTaskPopUp={openTaskPopUp}></InsightsTab>}
                      </dl>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
      {showTaskInspect ? <TaskPopUp
        header="Inspect Module Recommendation"
        leftButtonFunction={closeTaskPopUp}
        leftButtonLabel="Exit"
        rightButtonFunction={openTaskPopUp}
        rightButtonLabel="Assign"
        inspectData={inspectData}
      ></TaskPopUp> : null}
    </>
  )
}
