import React, { useState, useEffect } from 'react'
import AIFormattedText from "../TextComponents/AIFormattedText"
import BarChartComparison from "../Homedash/compBarChart"
import DoughnutMetric from "../HomePage/doughnutMetric"

export default function OverviewTab({
  overallAverageSimilarity,
  distributionDistance,
  barChartLabels,
  barChartData,
  barTopPerformerData,
  repName,
  report
}) {

  const overallSimPercentage = Math.round(overallAverageSimilarity * 10000) / 100

  return (
    <div class="mt-4 mx-2 flex flex-col gap-x-4">
      <div className="px-2">
        <p className="text-gray-400 whitespace-pre-line text-sm max-h-[30vh] overflow-y-scroll mb-10">
          <AIFormattedText content={report} small={true}></AIFormattedText>
        </p>
      </div>
      <div className="flex flex-row justify-between ">
        <div className="flex flex-col items-center justify-between my-12 min-h-[20vh] w-[45%]">
          <div className="flex flex-col items-center mx-auto">
            <h1 className="font-bold text-pareBlue text-2xl">N/A</h1>
            <label className="text-gray-400 text-sm">Roleplays Completed</label>
          </div>
          <div className="flex flex-col items-center mx-auto ">
            <h1 className="font-bold text-pareBlue text-2xl">N/A</h1>
            <label className="text-gray-400 text-sm">Quota Attainement</label>
          </div>
        </div>
        <div className="flex items-center justify-center w-[45%]">
          <DoughnutMetric percentage={overallSimPercentage} interiorLabel={"Top Rep Sim"}></DoughnutMetric>
        </div>
      </div>
      <div className="flex flex-col w-full mb-2">

        <BarChartComparison
          barChartLabels={barChartLabels}
          barChartData={barChartData}
          repName={repName} ></BarChartComparison>
      </div>
    </div>
  )
}
